import CorporateAdminDashboard from '../components/users/admin/dashboard/CorporateAdminDashboard';
import SuperAdminDashboard from '../components/users/superAdmin/dashboard/SuperAdminDashboard';
import UserDashboard from '../components/users/user/dashboard/UserDashboard';

export const dashboard = {
    CorporateAdminDashboard,
    SuperAdminDashboard,
    UserDashboard
}

