//List of pending requests
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';


const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const PendingList = ({ usersDetails }) => {
    const version = useVersion();
    const dataProvider = useDataProvider();

    useEffect(() => {
    }, [version])

    const classes = useStyles();



    return (
        <div className={classes.main}>
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    Users Pending Request
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {!usersDetails ? 0 : usersDetails.length}
                </Typography>
                <Divider />
                {!usersDetails ?
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="No Pending Requests"
                                secondary={'No Requests'}
                            />
                        </ListItem>
                    </List> :
                    <List style={{ maxHeight: '500px', overflow: 'auto' }}>
                        {usersDetails.map(record => (
                            <ListItem
                                button
                                key={record.id}
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar style={{ color: "black", backgroundColor: "#1a1b1c47", border: '1px solid black' }} Icon={AddCircleOutlineIcon} bgColor="#fff" />
                                </ListItemAvatar>

                                < ListItemText
                                    primary={`${record.name} `}
                                    secondary={`${record.designation}`}
                                />

                                < ListItemText
                                    primary={`${record.email}`}
                                    secondary={`${record.contactnumber}`}
                                />

                            </ListItem>
                        ))}
                    </List>}
            </Card>
        </div>
    );
};

export default PendingList;
