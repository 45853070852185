import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider, SimpleForm, SelectInput, useNotify } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import BusinessCard from './BusinessCard';
import ReferCard from './ReferCard';
import CorporateLocationList from './CorporateLocationList';
import PendingList from './PendingList';
import { NODE_API } from '../../../../config';
import axios from 'axios';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};
var userorganizationidd = ""

const CorporateAdminDashboard = () => {

  const [state, setState] = useState({});
  const version = useVersion();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const notify = useNotify();

  //Functions 

  //Get counts data for chart
  const fetchCount = useCallback(async () => {
    var refer_code, numberOfBusinessCards, name;

    await dataProvider.getList(
      `organization/count/organization/details/${userorganizationidd}`,
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'numberUser', order: 'DESC' }
      }
    )
      .then(res => {

        res.data.map(function (element) {
          name = element.name
          refer_code = element.refer_code
          numberOfBusinessCards = element.numberOfBusinessCards

        })


      })
      .catch(e => console.log('error : ' + e));

    setState(state => ({

      ...state,

      name: name,

      refer_code: refer_code,

      numberOfBusinessCards: numberOfBusinessCards

    }));
  }, [dataProvider])

  const userorganization = async function () {
    // var datadesign =[];
    let user = JSON.parse(sessionStorage.getItem('user'));
    // alert(user)
    let token;



    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    };

    // requestOptions
    await axios(`${NODE_API}/organization/list/userorganization`, requestOptions)

      .then(res => {

        var organizationlist = []

        res.data.data.map(val => {
          if (val.type_id == 2) {
            organizationlist.push({ id: val.id, value: val.name, label: val.name, name: val.name })

            userorganizationidd = val.id
          }

        })

        setState(state => ({

          ...state,
          id: userorganizationidd,
          organizationlist: organizationlist

        }))


      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })

      });


  }
  const getOrgDetails = useCallback(async () => {

    let details = []

    let locationDetails = []

    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    const requestOptions = {
      method: 'GET',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };

    await axios(`${NODE_API}/organization/details/${userorganizationidd}`, requestOptions)

      .then(res => {

        details = res.data

        details.locations.map(function (element) {

          locationDetails.push({
            name: element.name,
            city: element.city,
            address: element.address,
            id: element.id
          })


        })

        return res;

      }).catch(e => {

        notify(`Something went wrong try again later`, { type: 'warning' })

      });
    ;

    setState(state => ({

      ...state,

      locationDetails: locationDetails

    }));


  }, [dataProvider])

  const featchOrganizations = useCallback(async () => {
    var sendData = [];
    await dataProvider.getList(
      `organization/user/card/business/P/${userorganizationidd}`,
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'registration_date', order: 'DESC' }
      }
    )
      .then(res => {

        res.data.map(function (element) {
          sendData.push({ id: element.id, name: element.name, designation: element.designation, employeeid: element.employeeid, contactnumber: element.contactnumber, email: element.email })
        })


      })
      .catch(e => console.log('error : ' + e));
    ;

    setState(state => ({

      ...state,


      usersDetails: sendData,


    }));
  }, [dataProvider])
  const handleInputList = async (event) => {

    var idtarget = event.target.value
    userorganizationidd = idtarget
    setState(state => ({

      ...state,
      id: idtarget
    })
    )

    fetchCount()
    getOrgDetails()
    featchOrganizations()


  }
  useEffect(() => {
    userorganization().then(res=>{
      fetchCount()
      getOrgDetails()
      featchOrganizations()
    })
    
  }, [version])

  const { refer_code, numberOfBusinessCards, name, locationDetails, usersDetails } = state

  return isXSmall && isSmall ? (
    <>
      {state.organizationlist && state.organizationlist.length > 1 ? (

        <SimpleForm toolbar={false}>
          <SelectInput
            placeholder='Select organization'
            source="orgSelection"
            label="Select organization"
            fullWidth={true}
            required={true}
            defaultValue={state.id}
            onChange={handleInputList}
            style={{ width: "500px" }}
            choices={state.organizationlist}
          />
        </SimpleForm>
      ) : <></>}
      <div style={styles.flexColumn}>

        <div style={{ marginBottom: '2em' }}>
        </div>

        <div style={styles.flex}>
          {
            !refer_code ?
              <ReferCard value={"null"} /> :
              <ReferCard value={name} />
          }
          {
            !numberOfBusinessCards ?
              <BusinessCard value={0} /> :
              <BusinessCard value={numberOfBusinessCards} />
          }
        </div>
        <div style={styles.singleCol}>

          <div style={styles.singleCol}>
            <CorporateLocationList ocationDetails={locationDetails} />
          </div>

        </div>
        <div style={styles.singleCol}>

          <PendingList usersDetails={usersDetails} />

        </div>
      </div>
    </>
  ) :
    (
      <>

        <div style={styles.flex} >
          <div style={styles.leftCol}>
            <div style={styles.flex}>

              {
                !refer_code ?
                  <ReferCard value={"Organization"} /> :
                  <ReferCard value={name} />
              }

              {
                !numberOfBusinessCards ?
                  <BusinessCard value={0} /> :
                  <BusinessCard value={numberOfBusinessCards} />
              }
            </div>

            <CorporateLocationList locationDetails={locationDetails} />

          </div>

          <div style={styles.rightCol}>

            <div style={styles.singleCol}>
              {state.organizationlist && state.organizationlist.length > 1 ? (

                <SimpleForm toolbar={false}>
                  <SelectInput
                    placeholder='Select organization'
                    source="orgSelection"
                    label="Select organization"
                    fullWidth={true}
                    required={true}
                    defaultValue={state.id}
                    onChange={handleInputList}
                    choices={state.organizationlist}
                  />
                </SimpleForm>
              ) : <></>}
              <PendingList usersDetails={usersDetails} />
            </div>

          </div>

        </div>
      </>
    );

}
export default CorporateAdminDashboard