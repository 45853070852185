import React, { useState, useEffect } from "react";
import axios from "axios";
import { NODE_API } from "../../../../config";
import { Add, Delete } from "@material-ui/icons";
import {
  Button,
  Card,
  CardMedia,
  Grid,
  Typography,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";

const AdminTemplates = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [plainTemplates, setPlainTemplates] = useState([]);
  const [graphicTemplates, setGraphicTemplates] = useState([]);
  const [showPlainUpload, setShowPlainUpload] = useState(false);
  const [showGraphicUpload, setShowGraphicUpload] = useState(false);

  const [email, setEmail] = useState(
    sessionStorage.getItem("adminEmail") || ""
  );
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(!!email);
  const [showLoginPopup, setShowLoginPopup] = useState(!email);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCardTemplates();
    }
  }, [isAuthenticated]);

  const fetchCardTemplates = async () => {
    try {
      const response = await fetch(`${NODE_API}/card-template`);
      const data = await response.json();
      setPlainTemplates(data.plain);
      setGraphicTemplates(data.graphic);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      alert("Please enter email and password.");
      return;
    }
    try {
      const response = await axios.post(`${NODE_API}/admin/login`, {
        email,
        password,
      });

      if (response.data.success) {
        sessionStorage.setItem("adminEmail", email);
        setIsAuthenticated(true);
        setShowLoginPopup(false);
      }
    } catch (error) {
      alert("Invalid credentials!");
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const uploadTemplates = async (type) => {
    if (selectedFiles.length === 0)
      return alert("Please select files to upload");

    const uploadedTemplates = [];

    for (const file of selectedFiles) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64String = reader.result;

        try {
          const response = await axios.post(`${NODE_API}/card-template/add`, {
            timage: base64String,
            category: type,
          });

          uploadedTemplates.push(response.data);

          if (uploadedTemplates.length === selectedFiles.length) {
            window.location.reload();
          }
        } catch (error) {
          console.error("Upload Error:", error);
          alert("Error uploading templates");
        }
      };

      reader.onerror = (error) => console.error("FileReader Error:", error);
    }
  };

  const deleteTemplate = async (url) => {
    try {
      const response = await fetch(`${NODE_API}/card-template/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url, status:'inactive' }),
      });

      await response.json();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  if (!isAuthenticated) {
    return (
      <Dialog open={showLoginPopup} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>Admin Login</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary" variant="contained">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h4" gutterBottom>
        Admin: Manage Templates
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom>
              Plain Templates
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowPlainUpload(!showPlainUpload)}
              startIcon={<Add />}
              fullWidth
            >
              Add Plain Template
            </Button>

            {showPlainUpload && (
              <div style={{ marginTop: 10 }}>
                <input type="file" multiple onChange={handleFileChange} />
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => uploadTemplates("plain")}
                  style={{ marginLeft: 10 }}
                >
                  Upload
                </Button>
              </div>
            )}

            <Grid container spacing={2} style={{ marginTop: 20 }}>
              {plainTemplates.map((url, index) => (
                <Grid item key={index} xs={6} sm={3} md={2} lg={1.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="100"
                      image={url}
                      alt={`Plain ${index}`}
                    />
                    <CardContent style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => deleteTemplate(url)}
                        startIcon={<Delete />}
                        fullWidth
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom>
              Graphic Templates
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowGraphicUpload(!showGraphicUpload)}
              startIcon={<Add />}
              fullWidth
            >
              Add Graphic Template
            </Button>

            {showGraphicUpload && (
              <div style={{ marginTop: 10 }}>
                <input type="file" multiple onChange={handleFileChange} />
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => uploadTemplates("graphic")}
                  style={{ marginLeft: 10 }}
                >
                  Upload
                </Button>
              </div>
            )}

            <Grid container spacing={2} style={{ marginTop: 20 }}>
              {graphicTemplates.map((url, index) => (
                <Grid item key={index} xs={6} sm={3} md={2} lg={1.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="100"
                      image={url}
                      alt={`Graphic ${index}`}
                    />
                    <CardContent style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => deleteTemplate(url)}
                        startIcon={<Delete />}
                        fullWidth
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminTemplates;
