import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { OTPEncryption, OTPGenerator } from "../../utils/encrypt";
import { NODE_API } from "../../config";
import { history } from "../../helpers/history";

function DeleteAccount() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [view, setView] = useState("email");
  const [generatedOTP, setGeneratedOtp] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleOtpChange = (e) => setOtp(e.target.value);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Logic to send OTP to the user's email goes here
    const generatedOTPLocal = await OTPGenerator();
    setGeneratedOtp(generatedOTPLocal);
    console.log(generatedOTPLocal);
    var encryptedKey = OTPEncryption(generatedOTPLocal).toString();
    const response = await sendMailOTP(email, encryptedKey);
    setLoading(false);

    if (response.status == "success") {
      setView("otp");
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (otp == generatedOTP) {
      const response = await deleteAccount(email);
      if (response.status == "success") {
        toast.success(`Your account has been deleted successfully`);
        history.push("/login");
      }
    } else {
      toast.error(`Enter valid OTP`);
    }
  };

  const sendMailOTP = (email, otp) => {
    return new Promise(async (resolve) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: email,
          key: otp,
        }),
      };

      // requestOptions
      try {
        const res = await axios(
          `${NODE_API}/email/account/verification`,
          requestOptions
        );
        resolve({ status: "success" });
        return res;
      } catch (e) {
        if(e && e.response && e.response.status == 401){
            toast.error(`Account with this mail id does not exists`);
            resolve({status:"not exists"})
        }else if(e && e.response && e.response.data.message){
            toast.error(`Account with this mail id does not exists`);
            resolve({ status: "error" });
        }
    
      }
    });
  };

  const deleteAccount = (email) => {
    return new Promise(async (resolve) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: email,
        }),
      };

      // requestOptions
      try {
        const res = await axios(
          `${NODE_API}/user/account/delete`,
          requestOptions
        );
        resolve({ status: "success" });
        return res;
      } catch (e) {
        console.log("resssss error::", e);
        toast.error(`Something went wrong try again later`);
        resolve({ status: "error" });
      }
    });
  };

  const resendOtp = () => setView("email");
  return (
    <>
      <div
        style={{ backgroundColor: "#f8fafb", padding: "1%", marginTop: "1%" }}
      >
        <div className="content mt-2">
          <div className="row">
            <Toaster
              position="top-center"
              reverseOrder={false}
              containerStyle={{ padding: "2%" }}
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },

                // Default options for specific types
                success: {
                  duration: 3000,
                  theme: {
                    primary: "black",
                    secondary: "green",
                  },
                },
                error: {
                  duration: 3000,
                  theme: {
                    primary: "red",
                    secondary: "black",
                  },
                },
              }}
            />

            <div className="col-md-5 order-md-2 mt-4 justify-content-center align-content-center">
              <img
                src="/images/undraw_file_sync_ot38.png"
                alt="Image"
                className="img-fluid mt-4"
              />
            </div>

            <div className="col-md-6 contents justify-content-center align-content-center">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="container mt-5">
                    <h2>Delete Account</h2>
                    {view === "email" ? (
                      <form onSubmit={handleEmailSubmit}>
                        <div className="form-group">
                          <label htmlFor="email">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "SEND OTP"
                          )}
                        </button>
                      </form>
                    ) : (
                      <form onSubmit={handleOtpSubmit}>
                        <div className="form-group">
                          <label htmlFor="otp">Enter OTP shared on email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="otp"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={handleOtpChange}
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Verify
                        </button>
                        <div className="mt-3">
                          <a href="#" onClick={resendOtp}>
                            Did not get OTP? Click here to resend.
                          </a>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
