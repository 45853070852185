import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider, minLength, Create, SaveButton, ImageInput, ImageField, TextInput, SimpleForm, regex, List, required, useNotify, useRedirect, Edit, useVersion, Datagrid, TextField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import AddToOrganization from "./AddToOrganization";
import OrganizationDetails from "./OrganizationDetails";


export const styles = {

    refer: { width: 544 },

    city: { width: 544 },

    address: { width: 544 },

    singleCol: { marginTop: '2em', marginBottom: '2em' },

};

const useStyles = makeStyles(styles);

const validatecity = [required()]

const ValidateReferCode = (props) => {

    const redirect = useRedirect();
    const notify = useNotify();
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();


    async function checkReferCode(referCode) {

        let user = JSON.parse(sessionStorage.getItem('user'));

        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        return axios(`${NODE_API}/organization/refer?referCode=${referCode}`, requestOptions)

            .then(async res => {

                if (res.data.status && res.data.status === "Database error") {
                    notify(`Organization already register`, { type: 'warning' })
                }
                else if (res && res.status === 200) {

                    setState(state => ({

                        ...state,

                        LocationList: res.data,
                        referCode: referCode,
                        

                    }));

                }
                else if (res && res.status === 204) {
                    notify(`Enter Valid Refer Code`, { type: 'warning' })
                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });
    }


    const hasLocation = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));

        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'GET',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        axios(`${NODE_API}/organization/user/exist`, requestOptions)

            .then(async res => {


                if (res && res.status === 200) {
                    setState(state => ({

                        ...state,

                        hasOrganization: true,
                        organizationDetails: res.data[0]

                    }))
                }
                else if (res && res.status === 204) {
                    setState(state => ({

                        ...state,

                        hasOrganization: false,

                    }))

                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                    redirect('/')
                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });

    }, [dataProvider])


    useEffect(() => {
        hasLocation()
    }, [version])




    const onsubmit = async (data) => {

        await checkReferCode(data.referCode)

    };

    const { LocationList, referCode, hasOrganization, organizationDetails } = state


    return isXSmall && isSmall ? (

        <>


            {
                hasOrganization ?
                    <>
                        <OrganizationDetails data={organizationDetails} />
                    </> :
                    (referCode ?
                        <AddToOrganization LocationList={LocationList} referCode={referCode} /> :

                        <SimpleForm save={onsubmit}>
                            <Typography variant="h6" gutterBottom>
                                Add Organization
                            </Typography>
                            <TextInput
                                autoFocus
                                source="referCode"
                                fullWidth={true}
                                label="Enter Organization Refer Code"
                                formClassName={classes.refer}
                                validate={validatecity}
                            />
                        </SimpleForm>)
            }


        </>

    ) :

        (
            <>
                {

                    hasOrganization ?
                        <>
                            <OrganizationDetails data={organizationDetails} />
                        </> :

                        (referCode ?
                            <AddToOrganization LocationList={LocationList} referCode={referCode} /> :

                            <SimpleForm save={onsubmit}>
                                <Typography variant="h6" gutterBottom>
                                    Add Organization
                                </Typography>
                                <TextInput
                                    autoFocus
                                    source="referCode"
                                    fullWidth={true}
                                    label="Enter Organization Refer Code"
                                    formClassName={classes.refer}
                                    validate={validatecity}
                                />
                            </SimpleForm>)
                }


            </>
        );
};



export default ValidateReferCode;