import React, { useCallback, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { EditButton, useTranslate, NumberField, EmailField, TextField, useDataProvider, useVersion } from 'react-admin';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const MobileGrid = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();
    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        role && role === 'LOCATION_ADMIN' ? path = 'organization/location/users/cards/request/:id' :
            (role && role === 'CORPORATE_ADMIN' ? path = 'organization/users/cards/request/:id' : path = '/login')


        setState(state => ({

            ...state,

            path: path,

            role: role

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])


    return (
        <>
            {state && state.role == 'LOCATION_ADMIN' ?
                <div style={{ margin: '1em' }}>
                    {ids.map(id => (
                        <Card key={id} className={classes.card}>
                            <CardHeader
                                title={
                                    <div className={classes.cardTitleContent}>
                                        <h2>{`${data[id].name}`}</h2>
                                        <EditButton
                                            resource={`${state.path}`}
                                            basePath={basePath}
                                            record={data[id]}
                                        />
                                    </div>
                                }
                            />
                            <CardContent className={classes.cardContent}>
                                <div>
                                    {translate(
                                        'Emp ID'

                                    )}
                                    &nbsp;:&nbsp;
                                    <NumberField
                                        record={data[id]}
                                        source="employeeid"
                                        label="Emp ID"

                                    />
                                </div>
                                <div>
                                    {translate(
                                        'Email'
                                    )}
                                    &nbsp;
                                    <EmailField record={data[id]}
                                        source="email"
                                    />

                                </div>
                                <div>
                                    {translate(
                                        'Contact'

                                    )}
                                    &nbsp;:&nbsp;
                                    <TextField
                                        record={data[id]}
                                        source="contactnumber"
                                        label="Contact"

                                    />
                                </div>
                                <div>
                                    {translate(
                                        'Designation'
                                    )}
                                    &nbsp;
                                    <TextField record={data[id]}
                                        source="designation"
                                    />

                                </div>

                                <div>
                                    {translate(
                                        'Location'
                                    )}
                                    &nbsp;
                                    <TextField record={data[id]}
                                        source="locname"
                                    />

                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
                :
                (state.role == 'CORPORATE_ADMIN' ?
                    <div style={{ margin: '1em' }}>
                        {ids.map(id => (
                            <Card key={id} className={classes.card}>
                                <CardHeader
                                    title={
                                        <div className={classes.cardTitleContent}>
                                            <h2>{`${data[id].name}`}</h2>
                                            <EditButton
                                                resource={`${state.path}`}
                                                basePath={basePath}
                                                record={data[id]}
                                            />
                                        </div>
                                    }
                                />
                                <CardContent className={classes.cardContent}>
                                    <div>
                                        {translate(
                                            'Emp ID'

                                        )}
                                        &nbsp;:&nbsp;
                                        <NumberField
                                            record={data[id]}
                                            source="employeeid"
                                            label="Emp ID"

                                        />
                                    </div>
                                    <div>
                                        {translate(
                                            'Email'
                                        )}
                                        &nbsp;
                                        <EmailField record={data[id]}
                                            source="email"
                                        />

                                    </div>
                                    <div>
                                        {translate(
                                            'Contact'

                                        )}
                                        &nbsp;:&nbsp;
                                        <TextField
                                            record={data[id]}
                                            source="contactnumber"
                                            label="Contact"

                                        />
                                    </div>
                                    <div>
                                        {translate(
                                            'Designation'
                                        )}
                                        &nbsp;
                                        <TextField record={data[id]}
                                            source="designation"
                                        />

                                    </div>

                                </CardContent>
                            </Card>
                        ))}
                    </div>
                    : <></>
                )
            }
        </>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
