// This component renders the location list section on dashboard
import React, { useEffect, useState } from "react";
import { useDataProvider, required, useNotify, SearchInput, useVersion, TopToolbar, CreateButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useMediaQuery } from '@material-ui/core';

export const styles = {

  city: { width: 544 },

  address: { width: 544 },

  singleCol: { marginTop: '2em', marginBottom: '2em' },

  card: {
    padding: '16px 0',
    overflow: 'inherit',
    width: '85%'
  },
  title: {
    padding: '0 16px',
    marginBottom: '2%'
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },

};

const useStyles = makeStyles(styles);

const CorporateLocationList = (props) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const version = useVersion();

  useEffect(() => {
  }, [version])

  const postFilters = [
    <SearchInput source="q" alwaysOn />
  ];

  const ListActions = () => (
    <TopToolbar>

      <CreateButton />

    </TopToolbar>
  );

  return isXSmall && isSmall ? (

    <div style={{ marginTop: '3%', marginLeft: '2%' }}>

      <Card className={classes.card} >

        <Typography
          className={classes.title} color="textSecondary"
        >
          Location List

        </Typography>

        <Divider />

        <List {...props} actions={<ListActions />} exporter={false} filters={postFilters} style={{ maxHeight: '500px', overflow: 'auto' }}>
          {props.locationDetails && props.locationDetails.map(record => (
            <ListItem
              button
              key={record.id}
              alignItems='flex-start'
            >
              < ListItemText
                primary={`${record.name}`}
                secondary={`${record.city} - \t ${record.address}`}

              />

            </ListItem>

          ))}
        </List>
      </Card>
    </div >

  )
    :
    (
      <div style={{ marginTop: '3%' }}>

        <Card className={classes.card} >

          <Typography variant="h6"
            className={classes.title} color="textSecondary">
            Location List

          </Typography>

          <Divider />

          <List {...props} actions={<ListActions />}
            exporter={false} filters={postFilters}
            style={{ maxHeight: '500px', overflow: 'auto' }}>

            {props.locationDetails && props.locationDetails.map(record => (

              <ListItem
                button
                key={record.id}
                alignItems='flex-start'
              >
                < ListItemText
                  primary={`${record.name}`}
                  secondary={`${record.city} - \t ${record.address}`}

                />

              </ListItem>

            ))}
          </List>
        </Card>
      </div >
    );
};



export default CorporateLocationList;