import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    SearchInput,
    SimpleList,
    ImageField,

} from 'react-admin';
import { makeStyles, useMediaQuery } from '@material-ui/core';


const ListFilters = props => (
    <Filter {...props}>
        <SearchInput source="q" resettable alwaysOn />
    </Filter>
);

const useImageFieldStyles = makeStyles(theme => ({
    image: {
        width: 40,
        height: 40,
        objectFit: 'contain',
        borderRadius: '50%',
        position: 'relative',
    }
}));

export const styles = {

    refer: { width: 544 },

    city: { width: 544 },

    address: { width: 544 },

    singleCol: { marginTop: '2em', marginBottom: '2em' },

    img: { width: '50%', height: '50px', borderRadius: '50%' }

};

const OrganizationList = props => {


    const imageFieldClasses = useImageFieldStyles();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));


    const CustomEmpty = () => <div>No Organization Registered</div>;


    return isXSmall && isSmall ?
        (

            <>
              
                <List {...props}
                    filters={<ListFilters />}
                    perPage={7}
                    bulkActionButtons={false}
                    sort={{ field: 'name', order: 'ASC' }}
                    empty={<CustomEmpty/>}
                    hasEdit={false}
                    pagination={false}
                >
                    <SimpleList className='text-left'
                        leftAvatar={record => record.imageUrl}
                        primaryText={record => record.name}
                        secondaryText={record => `${record.domain}`}
                        tertiaryText={record => new Date(record.registration_date).toLocaleDateString()}
                    />
                    
                </List>

            </>
        ) :
        (
            <>
                <List {...props}
                    filters={<ListFilters />}
                    perPage={7}
                    bulkActionButtons={false}
                    sort={{ field: 'name', order: 'ASC' }}
                    pagination={false}

                >
                    <Datagrid >
                        <div style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                        }}>

                            <ImageField source='imageUrl' src='imageUrl' classes={imageFieldClasses} className="thumbnail" />

                            &nbsp;&nbsp;&nbsp;

                            <TextField source="name" />

                        </div>
                        <DateField source="registration_date" />
                        <TextField source="domain" />
                        <TextField source="website" />

                    </Datagrid>
                </List>

            </>
        )
};

export default OrganizationList;
