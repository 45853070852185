import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useTranslate, useUpdate, useNotify, useRedirect, useVersion, useDataProvider } from 'react-admin';

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();

    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        role && role === 'LOCATION_ADMIN' ? path = 'organization/location/users/cards/request' :
            (role && role === 'CORPORATE_ADMIN' ? path = 'organization/users/cards/request' : path = '/login')


        setState(state => ({

            ...state,

            path: path

        }));

    }, [dataProvider])

    
  useEffect(() => {
    checkUserRole()
  }, [version])


    const [reject, { loading }] = useUpdate(        
        `${state.path}`,
        record.id,
        { is_approved:  'R'},
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    'Request is rejected',
                    'info',
                    {},
                    true
                );
                redirectTo(`/${state.path}`);
            },
            onFailure: () => {
                notify(
                    'Can not reject this error',
                    'warning'
                );
            },
        }
    );

    return record && record.is_approved === 'P' ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={reject}
            disabled={loading}
        >
            <ThumbDown
                color="primary"
                style={{ paddingRight: '0.5em', color: 'red' }}
            />
            {translate('reject')}
        </Button>
    ) : (
        <span />
    );
};

RejectButton.propTypes = {
    record: PropTypes.object,
};

export default RejectButton;
