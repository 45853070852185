import React, { useState, useEffect, useCallback, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider, useVersion, useNotify, translate } from "react-admin";
import "../cards/card.css";
import { NODE_API, USER_PROFILE_IMAGE } from "../../../../config";
import axios from "axios";
import html2canvas from "html2canvas";
import { Button, useMediaQuery } from "@material-ui/core";
import { flipInY } from "react-animations";
import Radium from "radium";
import MuiToolbar from "@material-ui/core/Toolbar";
import AcceptButton from "./AcceptButton";
import RejectButton from "./RejectButton";
import { SkipNextOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    height:"65px",
    width:"660px"
  },
  override: {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  },
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },

  singleCol: { marginTop: "2em", marginBottom: "2em" },
  name: { display: "inline-block", marginLeft: "1em" },
  displayblock: { display: "inline-block" },
  indicator: {
    color: "#F00",
  },
});

const styles = {
  flipInY: {
    animation: "s 2s",
    animationName: Radium.keyframes(flipInY, "flipInY"),
  },
};

var firstName = "",
  lastName = "",
  title = "",
  email = "",
  contact = "",
  address = "";

const RequestEditToolbar = ({
  basePath,
  handleSubmitWithRedirect,
  invalid,
  record,
  resource,
  saving,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(0);
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const changefront = (data) => {
    setFront(data);
  };
  const changeback = (data) => {
    setBack(data);
  };

  const FrontView = ({ changefront }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleConfirm = () => {
      html2canvas(document.querySelector(".card"), {
        dpi: 300,
        scale: 4,
        useCORS: true,
      }).then(function (canvas) {
        var img = canvas.toDataURL("image/png");
        changefront(img);
      });
    };

    useEffect(() => {
      fetchcards().then((users) => handleConfirm());
    }, []);

    const setDetails = (details, cardDesign) => {
      if (details) {
        document.getElementById("nameset").innerHTML = details.name;
        document.getElementById("designationset").innerHTML = details.designation;
        // document.getElementById("empoyeeset").innerHTML = details.employeeid;
        document.getElementById("contactset").innerHTML = details.contactnumber;
        document.getElementById("emailset").innerHTML = details.email;
        document.getElementById("addressset").innerHTML = details.address;
  
        if (cardDesign) {
          cardDesign.map(function (element) {
            if (element.isDisable != true) {
              if (
                element.name == "nameset" ||
                element.name == "designationset" ||
                element.name == "empoyeeset" ||
                element.name == "contactset" ||
                element.name == "emailset" ||
                element.name == "addressset"
              ) {
                if (element.position.left <= 190) {
                  console.log("Left Aligned");
                } else if (
                  element.position.left >= 191 &&
                  element.position.left <= 210
                ) {
                  console.log("Center Aligned");
                } else {
  
                  var widthDiff = 400 - element.position.left;
  
                  const myDiv = document.getElementById(element.position.name);
  
                  myDiv.style.left = ""; 
  
                  if (widthDiff < 10) {
                    myDiv.style.right = "30px";
                  } else {
                    myDiv.style.right = widthDiff + "px";
                  }
                }
              }
            }
          });
          var value = details.name;
  
          var convertvalue = value.split(" ");
          if (convertvalue.length === 2) {
            firstName = convertvalue[0];
            lastName = convertvalue[1];
          } else {
            firstName = details.name;
          }
          title = details.designation;
          contact = details.contactnumber.toString();
          email = details.email;
        }
        //for scanner
      }
      return;
    };

    const setDesign = async (design) => {
      if (design) {
        design.map(function (element) {
          if (element.isDisable != true) {
            var topvalue = element.position.top + "px";

            document.getElementById(element.position.name).style.top = topvalue;

            var leftvalue = element.position.left + "px";
            document.getElementById(element.position.name).style.left = leftvalue;
            if (element.name === "mydivvl") {

              document.getElementById("mydivvl").style.width =
                element.position.width + "px";
              document.getElementById("mydivvl").style.backgroundColor =
                element.position.backgroundColor;
              document.getElementById(element.position.name).style.cursor =
                "default";
            } else if (element.name === "mydivhl") {
              document.getElementById("mydivhl").style.height =
                element.position.height;
              document.getElementById("mydivhl").style.borderColor =
                element.position.borderColor;
              document.getElementById(element.position.name).style.cursor =
                "default";
            } else if (
              element.name != "logo" &&
              element.name != "userprofile"
            ) {
              if (
                element.name === "sloganset" ||
                element.name === "companynameset" ||
                element.name === "regiaddressset" ||
                element.name === "regiaddress2set" ||
                element.name === "regiaddress3set"

              ) {
                document.getElementById(element.name).innerHTML = element.value;
              }
              document.getElementById(element.name).style.fontFamily =
                element.textStyle;
              document.getElementById(element.name).style.textDecoration =
                element.fontStyle.underline;
              document.getElementById(element.name).style.fontStyle =
                element.fontStyle.italic;
              document.getElementById(element.name).style.fontWeight =
                element.fontStyle.bold;
              document.getElementById(element.name).style.fontSize =
                element.fontSize;
              document.getElementById(element.name).style.color =
                element.fontColor;
              document.getElementById(element.position.name).style.cursor =
                "default";
            } else if (element.name == "userprofile") {
              if (element.backgroundtemplate != null)
                document.getElementById(
                  "card"
                ).style.backgroundImage = `url(${element.backgroundtemplate})`;

              if (element.value != null)
                document.getElementById(
                  "mydivuserprofile"
                ).style.backgroundImage = `url(${USER_PROFILE_IMAGE}/${record.user_id}/profile.png)`;
              document.getElementById("mydivuserprofile").style.width =
                element.position.width;
              document.getElementById("mydivuserprofile").style.height =
                element.position.height;
              document.getElementById(element.position.name).style.border =
                "none";
              document.getElementById("userprofile").style.cursor = "default";
            } else {
              if (element.backgroundtemplate != null)
                document.getElementById(
                  "card"
                ).style.backgroundImage = `url(${element.backgroundtemplate})`;

              if (element.value != null)
                document.getElementById(
                  "mydivlogo"
                ).style.backgroundImage = `url(${element.value})`;
              document.getElementById("mydivlogo").style.width =
                element.position.width;
              document.getElementById("mydivlogo").style.height =
                element.position.height;
              document.getElementById(element.position.name).style.border =
                "none";
              document.getElementById("logo").style.cursor = "default";
            }
          } else {
            if (element.name === "logo") {
              document.getElementById("mydivlogo").style.visibility = "hidden";
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;
            } else if (element.name === "userprofile") {
              document.getElementById("mydivuserprofile").style.visibility =
                "hidden";
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;
            } else if (element.name === "vlset") {
              document.getElementById("mydivvl").style.visibility = "hidden";
            } else if (element.name === "hlset") {
              document.getElementById("mydivhl").style.visibility = "hidden";
            } else {
              document.getElementById(element.name).style.visibility = "hidden";
            }
          }
        });
      }
      return;
    };

    const fetchcards = useCallback(async () => {
      let user = JSON.parse(sessionStorage.getItem("user"));
      let token;

      if (user && user.validator) {
        token = user.validator;
      } else {
        token = {};
      }

      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: JSON.stringify({
          id: record.id,
          name: record.organizationname,
          userid: record.user_id,
        }),
      };

      // requestOptions
      await axios(
        `${NODE_API}/card/business/design/adminapprove`,
        requestOptions
      )
        .then(async (res) => {
          await setDesign(res.data[0].front);
          await fetchdetailsoncard(res.data[0].front);
        })
        .catch((e) => {
          notify(`Something went wrong try again later` + e, {
            type: "warning",
          });
        });
    }, [dataProvider]);

    //Get list of organizations
    const fetchdetailsoncard = useCallback(
      async (cardDesign) => {
        let user = JSON.parse(sessionStorage.getItem("user"));
        let token;

        if (user && user.validator) {
          token = user.validator;
        } else {
          token = {};
        }

        const requestOptions = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          data: JSON.stringify({
            id: record.id,
            name: record.organizationname,
            userid: record.user_id,
          }),
        };

        // requestOptions
        await axios(`${NODE_API}/card/business/adminapprove`, requestOptions)
          .then(async (res) => {
            await setDetails(res.data[0], cardDesign);
            return;
          })
          .catch((e) => {
            notify(`Something went wrong try again later`, { type: "warning" });
          });
      },
      [dataProvider]
    );

    return (
      <>
        <div className="main">
          <div className="settingView">
            <div className="card" id="card" style={{ marginTop: "1em" }}>
              <span id="mydivlogo" className="mydivlogo">
                <div className="logo" id="logo"></div>
              </span>
              <span id="mydivuserprofile" className="mydivuserprofile">
                <div className="userprofile" id="userprofile"></div>
              </span>
              <span id="mydivname">
                <p className="name" id="nameset"></p>
              </span>
              <span id="mydivcompanyname">
                <p className="companyname" id="companynameset"></p>
              </span>
              <span id="mydivregiaddress">
                <p className="regiaddress" id="regiaddressset">
                  Registered Address 1
                </p>
              </span>
              <span id="mydivregiaddress2">
                <p className="regiaddress2" id="regiaddress2set">
                  Registered Address 2
                </p>
              </span>
              <span id="mydivregiaddress3">
                <p className="regiaddress3" id="regiaddress3set">
                  Registered Address 3
                </p>
              </span>
              <span id="mydivslogan">
                <p className="slogan" id="sloganset"></p>
              </span>
              <span id="mydivcontact">
                <p className="contact" id="contactset"></p>
              </span>
              <span id="mydivdesignation">
                <p className="designation" id="designationset"></p>
              </span>
              <span id="mydivempoyeeid">
                <p className="empoyee" id="empoyeeset"></p>
              </span>
              <span id="mydivaddress">
                <p className="address" id="addressset"></p>
              </span>
              <span id="mydivemail">
                <p className="email" id="emailset"></p>
              </span>
              <span id="mydivhl">
                <hr className="hl" id="hlset" />
              </span>
              <span id="mydivvl">
                <p className="vl" id="vlset"></p>
              </span>
            </div>
          </div>
        </div>
        <a href="#" id="link" download="visiting card.png"></a>
      </>
    );
  };

  const BackView = ({ changeback }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const handleConfirm = () => {
      html2canvas(document.querySelector(".card"), {
        dpi: 144,
        scale: 4,
        useCORS: true,
      }).then(function (canvas) {
        var img = canvas.toDataURL("image/png");
        changeback(img);
      });
    };
    useEffect(() => {
      fetchcards().then((users) => handleConfirm());
    }, []);

    const setDesign = (design) => {
      if (design) {
        design.map(function (element) {
          if (element.isDisable != true) {
            var topvalue = element.position.top + "px";
            document.getElementById(element.position.name).style.top = topvalue;
            var leftvalue = element.position.left + "px";
            document.getElementById(element.position.name).style.left =
              leftvalue;
            if (element.name === "mydivvl") {
              document.getElementById("mydivvl").style.width =
                element.position.width + "px";
              document.getElementById("mydivvl").style.backgroundColor =
                element.position.backgroundColor;
              document.getElementById(element.position.name).style.cursor =
                "default";
            } else if (element.name === "mydivhl") {
              document.getElementById("mydivhl").style.height =
                element.position.height;
              document.getElementById("mydivhl").style.borderColor =
                element.position.borderColor;
              document.getElementById(element.position.name).style.cursor =
                "default";
            } else if (element.name != "logo") {
              document.getElementById(element.name).innerHTML = element.value;
              document.getElementById(element.name).style.fontFamily =
                element.textStyle;
              document.getElementById(element.name).style.textDecoration =
                element.fontStyle.underline;
              document.getElementById(element.name).style.fontStyle =
                element.fontStyle.italic;
              document.getElementById(element.name).style.fontWeight =
                element.fontStyle.bold;
              document.getElementById(element.name).style.fontSize =
                element.fontSize;
              document.getElementById(element.name).style.color =
                element.fontColor;
              document.getElementById(element.position.name).style.cursor =
                "default";

              if (element.name === "nameset") {
                var value = element.value;
                var convertvalue = value.split(" ");
                if (convertvalue.length === 2) {
                  firstName = convertvalue[0];
                  lastName = convertvalue[1];
                } else {
                  firstName = element.value;
                }
              }
              if (element.name === "sloganset") {
                title = element.value;
              }
              if (element.name === "contactset") {
                contact = element.value;
              }
              if (element.name === "emailset") {
                email = element.value;
              }
              if (element.name === "addressset") {
                address = element.value;
              }
            } else {
              if (element.backgroundtemplate != null)
                document.getElementById(
                  "card"
                ).style.backgroundImage = `url(${element.backgroundtemplate})`;

              if (element.value != null)
                document.getElementById(
                  "mydivlogo"
                ).style.backgroundImage = `url(${element.value})`;
              document.getElementById("mydivlogo").style.width =
                element.position.width;
              document.getElementById("mydivlogo").style.height =
                element.position.height;
              document.getElementById(element.position.name).style.border =
                "none";
              document.getElementById("logo").style.cursor = "default";
            }
          } else {
            if (element.name === "logo") {
              document.getElementById("mydivlogo").style.visibility = "hidden";
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;
            } else if (element.name === "vlset") {
              document.getElementById("mydivvl").style.visibility = "hidden";
            } else if (element.name === "hlset") {
              document.getElementById("mydivhl").style.visibility = "hidden";
            } else {
              document.getElementById(element.name).style.visibility = "hidden";
            }
          }
        });
      }
      return;
    };

    const fetchcards = useCallback(async () => {
      let user = JSON.parse(sessionStorage.getItem("user"));
      let token;

      if (user && user.validator) {
        token = user.validator;
      } else {
        token = {};
      }

      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: JSON.stringify({
          id: record.id,
          name: record.organizationname,
          userid: record.user_id,
        }),
      };

      // requestOptions
      await axios(
        `${NODE_API}/card/business/design/adminapprove`,
        requestOptions
      )
        .then((res) => {
          setDesign(res.data[0].back);
        })
        .catch((e) => {
          notify(`Something went wrong try again later`, { type: "warning" });
        });
    }, [dataProvider]);

    return isSmall && isXSmall ? (
      <>
        <div className="main">
          <div className="setting">
            <div
              className="cardMobile"
              id="card"
              style={{ marginTop: "1em", marginLeft: "1em" }}
            >
              <span id="mydivlogo" className="mydivlogo">
                <div className="logo" id="logo"></div>
              </span>
              <span id="mydivname">
                <p className="name" id="nameset"></p>
              </span>
              <span id="mydivslogan">
                <p className="slogan" id="sloganset"></p>
              </span>
              <span id="mydivcontact">
                <p className="contact" id="contactset"></p>
              </span>
              <span id="mydivdesignation">
                <p className="designation" id="designationset"></p>
              </span>
              <span id="mydivempoyeeid">
                <p className="empoyee" id="empoyeeset"></p>
              </span>
              <span id="mydivaddress">
                <p className="address" id="addressset"></p>
              </span>
              <span id="mydivemail">
                <p className="email" id="emailset"></p>
              </span>
              <span id="mydivhl">
                <hr className="hl" id="hlset" />
              </span>
              <span id="mydivvl">
                <p className="vl" id="vlset"></p>
              </span>
            </div>
          </div>
        </div>

        <a href="#" id="link" download="visiting card.png"></a>
      </>
    ) : (
      <>
        <div className="main">
          <div className="setting">
            <div className="card" id="card" style={{ marginTop: "1em" }}>
              <span id="mydivlogo" className="mydivlogo">
                <div className="logo" id="logo"></div>
              </span>
              <span id="mydivname">
                <p className="name" id="nameset"></p>
              </span>
              <span id="mydivslogan">
                <p className="slogan" id="sloganset"></p>
              </span>
              <span id="mydivcontact">
                <p className="contact" id="contactset"></p>
              </span>
              <span id="mydivdesignation">
                <p className="designation" id="designationset"></p>
              </span>
              <span id="mydivempoyeeid">
                <p className="empoyee" id="empoyeeset"></p>
              </span>
              <span id="mydivaddress">
                <p className="address" id="addressset"></p>
              </span>
              <span id="mydivemail">
                <p className="email" id="emailset"></p>
              </span>
              <span id="mydivhl">
                <hr className="hl" id="hlset" />
              </span>
              <span id="mydivvl">
                <p className="vl" id="vlset"></p>
              </span>
            </div>
          </div>
        </div>

        <a href="#" id="link" download="visiting card.png"></a>
      </>
    );
  };

  return (
    <div>
      {state == 0 ? (
        <div className="m-2 p-1 align-items-center">
          <FrontView changefront={changefront} />
          {/* <button
            className="btn  btn-info btn-sm mt-2"
            onClick={() => setState(1)}
          >
            NEXT
          </button> */}
          <MuiToolbar className={classes.root}>
            {record.is_approved === "P" ? (
              <Fragment>
                <RejectButton record={record} />
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setState(1)}
                >
                  <SkipNextOutlined
                    color="primary"
                    style={{ paddingRight: "0.5em", color: "red" }}
                  />
                  NEXT
                </Button>
              </Fragment>
            ) : (
              <></>
            )}
          </MuiToolbar>
        </div>
      ) : (
        <>
          <BackView changeback={changeback} />
          <MuiToolbar className={classes.root}>
            {record.is_approved === "P" ? (
              <Fragment>
                <RejectButton record={record} />
                <AcceptButton
                  record={record}
                  frontImageURI={front}
                  backImageURI={back}
                />
              </Fragment>
            ) : (
              <></>
            )}
          </MuiToolbar>
        </>
      )}
    </div>
  );
};

export default RequestEditToolbar;
