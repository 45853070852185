export function AuthHeaderJWT() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.token) {

        return { 'Authorization': 'Bearer ' + user.validator };
    } else {
        return {};
    }
}