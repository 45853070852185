import { NODE_API } from '../config'
import axios from 'axios';
import { AuthHeaderJWT } from '../helpers/AuthHeaderJWT';
var CryptoJS = require("crypto-js");

export const userService = {
    login,
    logout,
    register,
    sendOTPMail,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(username, password) {
    var passwordEncryption = CryptoJS.SHA3(password);

    var encryptedLoginPassword = passwordEncryption.toString(CryptoJS.enc.Base64)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ email: username, password: encryptedLoginPassword })
    };

    return axios(`${NODE_API}/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: AuthHeaderJWT()
    };

    return fetch(`${NODE_API}/auth/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: AuthHeaderJWT()
    };

    return fetch(`${NODE_API}/auth/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {

    var passwordEncryption = CryptoJS.SHA3(user.password);

    var encryptedLoginPassword = passwordEncryption.toString(CryptoJS.enc.Base64)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ email: user.email, password: encryptedLoginPassword, username: user.name })
    };

    return axios(`${NODE_API}/auth/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user;
        });
}


function sendOTPMail(email, otp) {

    var ReqBody = {
        email: email,
        key: otp
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(ReqBody)
    };


    return axios(`${NODE_API}/email/verification`, requestOptions)
        .then(handleResponse)

}


function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...AuthHeaderJWT(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${NODE_API}/auth/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: AuthHeaderJWT()
    };

    return fetch(`${NODE_API}/auth/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    // return response.data().then(text => {
    const data = response.data;
    if (response.statusText !== 'OK') {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;

}

