import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  useDataProvider,
  useNotify,
  SelectInput,
  CheckboxGroupInput,
  SimpleForm,
  TextInput,
  ImageInput,
} from "react-admin";
import "../card.css";
import { NODE_API } from "../../../../../config";
import axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import domtoimage from "dom-to-image";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles({
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em", width: "50%" },
  rightCol: { flex: 1, marginLeft: "1em", width: "50%" },
  marginLeft: { marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
  name: { display: "inline-block", marginLeft: "1em" },
  displayblock: { display: "inline-block" },
  colorStyle: { backgroundColor: "#fafaf" },
});

//Variable for local use
var boldboolean = 0,
  italicboolean = 0,
  underlineboolean = 0;
var viewDesign = 0;
var bboldboolean = 0,
  bitalicboolean = 0,
  bunderlineboolean = 0;
var nameboolean = 0,
  titleboolean = 0,
  contactboolean = 0,
  imgboolean = 0,
  emailboolean = 0,
  addressboolean = 0,
  vlboolean = 0,
  hlboolean = 0;
var bvlboolean = 0,
  bhlboolean = 0,
  bnameboolean = 0,
  btitleboolean = 0,
  bimgboolean = 0;
var arraycheck = [];
var addressline2boolean = 0;
var addressline3boolean = 0;

const FrontView = ({ templateData }) => {
  const [state, setState] = useState({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [arrayCheck, setArrayCheck] = useState([]);

  const [showPlain, setShowPlain] = useState(true);
  const [showGraphic, setShowGraphic] = useState(false);
  const [existingImages, setExistingImages] = useState(new Set());

  const [activeBgType, setActiveBgType] = useState("plain");
  const [activeBgIndex, setActiveBgIndex] = useState(null);
  const [defaultBgTemplate, setDefaultBgTemplate] = useState(null);

  const handleImageLoad = (index) => {
    setExistingImages((prev) => new Set([...prev, index]));
  };

  const handleImageError = (index) => {
    setExistingImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });
  };
  useEffect(() => {
    //changing the background
    const bgs = document.querySelectorAll(".backgrounds img");
    const card = document.querySelector(".card");
    let activeBg = 0; //default background

    setState((state) => ({
      ...state,

      getname: "nameset",
    }));

    bgs.forEach((item, i) => {
      item.addEventListener("click", () => {
        bgs[activeBg].classList.remove("active");
        item.classList.add("active");
        card.style.backgroundImage = `url('${item.src}')`;
        activeBg = i;
      });
    });

    // setting up logo
    const uploadBtn = document.querySelector("#upload");
    const mydivlogo = document.querySelector(".mydivlogo");
    uploadBtn.addEventListener("change", () => {
      if (uploadBtn.files && uploadBtn.files[0]) {
        let reader = new FileReader(); // init the file reader
        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          mydivlogo.style.backgroundImage = `url('${reader.result}')`;
        });

        reader.readAsDataURL(uploadBtn.files[0]);
      }
    });
    // setting background image
    const BackgroundTemplates = document.querySelector("#BackgroundTemplates");
    const BackgroundTemplatescard = document.querySelector("#card");
    BackgroundTemplates.addEventListener("change", () => {
      if (BackgroundTemplates.files && BackgroundTemplates.files[0]) {
        let reader = new FileReader(); // init the file reader
        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          BackgroundTemplatescard.style.backgroundImage = `url('${reader.result}')`;
        });

        reader.readAsDataURL(BackgroundTemplates.files[0]);
      }
    });
    //setting up name
    const cardname = document.querySelector("#cardnameupdate");
    cardname.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("nameset").innerHTML = text;
    });

    //setting up slogan
    const sloganupdate = document.querySelector("#sloganupdate");

    sloganupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("sloganset").innerHTML = text;
    });

    //setting up contact
    const contactupdate = document.querySelector("#contactupdate");

    contactupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("contactset").innerHTML = text;
    });

    //setting up address
    const addressupdate = document.querySelector("#addressupdate");

    addressupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("addressset").innerHTML = text;
    });

    const address2update = document.querySelector("#address2update");

    address2update.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("address2set").innerHTML = text;
    });

    const address3update = document.querySelector("#address3update");

    address3update.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("address3set").innerHTML = text;
    });

    //setting up email
    const emailupdate = document.querySelector("#emailupdate");

    emailupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("emailset").innerHTML = text;
    });

    //hide and unhide fields
    const check = document.querySelector("#check");
    check.addEventListener("change", (e) => {
      if (e.target.value == "namecheck") {
        if (nameboolean === 0) {
          document.getElementById("nameset").style.visibility = "hidden";
          nameboolean = 1;
        } else {
          document.getElementById("nameset").style.visibility = "visible";
          nameboolean = 0;
        }
      }
      if (e.target.value == "titlecheck") {
        if (titleboolean === 0) {
          document.getElementById("sloganset").style.visibility = "hidden";
          titleboolean = 1;
        } else {
          document.getElementById("sloganset").style.visibility = "visible";
          titleboolean = 0;
        }
      }
      if (e.target.value == "contactcheck") {
        if (contactboolean === 0) {
          document.getElementById("contactset").style.visibility = "hidden";
          contactboolean = 1;
        } else {
          document.getElementById("contactset").style.visibility = "visible";
          contactboolean = 0;
        }
      }
      if (e.target.value == "emailcheck") {
        if (emailboolean === 0) {
          document.getElementById("emailset").style.visibility = "hidden";
          emailboolean = 1;
        } else {
          document.getElementById("emailset").style.visibility = "visible";
          emailboolean = 0;
        }
      }
      if (e.target.value == "imagecheck") {
        if (imgboolean === 0) {
          document.getElementById("mydivlogo").style.visibility = "hidden";
          imgboolean = 1;
        } else {
          document.getElementById("mydivlogo").style.visibility = "visible";
          imgboolean = 0;
        }
      }
      if (e.target.value == "addresscheck") {
        if (addressboolean === 0) {
          document.getElementById("addressset").style.visibility = "hidden";
          addressboolean = 1;
        } else {
          document.getElementById("addressset").style.visibility = "visible";
          addressboolean = 0;
        }
      }
      if (e.target.value == "address2check") {
        if (addressline2boolean === 0) {
          document.getElementById("address2set").style.visibility = "hidden";
          addressline2boolean = 1;
        } else {
          document.getElementById("address2set").style.visibility = "visible";
          addressline2boolean = 0;
        }
      }
      if (e.target.value == "address3check") {
        if (addressline3boolean === 0) {
          document.getElementById("address3set").style.visibility = "hidden";
          addressline3boolean = 1;
        } else {
          document.getElementById("address3set").style.visibility = "visible";
          addressline3boolean = 0;
        }
      }
      if (e.target.value == "vlcheck") {
        if (vlboolean === 0) {
          document.getElementById("mydivvl").style.visibility = "hidden";
          vlboolean = 1;
        } else {
          document.getElementById("mydivvl").style.visibility = "visible";
          vlboolean = 0;
        }
      }
      if (e.target.value == "hlcheck") {
        if (hlboolean === 0) {
          document.getElementById("mydivhl").style.visibility = "hidden";
          hlboolean = 1;
        } else {
          document.getElementById("mydivhl").style.visibility = "visible";
          hlboolean = 0;
        }
      }
    });

    //Make the DIV element draggagle:
    dragElement(document.getElementById("mydivaddress"));
    dragElement(document.getElementById("mydivaddress2"));
    dragElement(document.getElementById("mydivaddress3"));
    dragElement(document.getElementById("mydivemail"));
    dragElement(document.getElementById("mydivname"));
    dragElement(document.getElementById("mydivslogan"));
    dragElement(document.getElementById("mydivcontact"));
    dragElement(document.getElementById("mydivvl"));
    dragElement(document.getElementById("mydivhl"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    initDragElement();
    initResizeElement();

    function initDragElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("mydivlogo");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("logo");
        if (headerItems.length === 1) {
          return headerItems[0];
        }
        return null;
      }
    }

    function initResizeElement() {
      var popups = document.getElementsByClassName("mydivlogo");
      var element = null;
      var startX, startY, startWidth, startHeight;

      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];

        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;

        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;

        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    }
    fetchcards();
  }, []);
  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";
          document.getElementById(element.position.name).style.top = topvalue;
          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;

          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width;
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
          } else if (element.name != "logo") {
            document.getElementById(element.name).innerHTML = element.value;
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
          } else {
            if (element.backgroundtemplate != null)
            setDefaultBgTemplate(element.backgroundtemplate);

            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null) 
            document.getElementById(
              "mydivlogo"
            ).style.backgroundImage = `url(${element.value})`;
            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
          }
        } else {
          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;
            arraycheck.push("imagecheck");
            setArrayCheck((prevArray) => [...prevArray, "imagecheck"]);
            imgboolean = 1;
          } else if (element.name === "addressset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("addresscheck");
            setArrayCheck((prevArray) => [...prevArray, "addresscheck"]);
            addressboolean = 1;
          } else if (element.name === "address2set") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("address2check");
            setArrayCheck((prevArray) => [...prevArray, "address2check"]);
            addressline2boolean = 1;
          } else if (element.name === "address3set") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("address3check");
            setArrayCheck((prevArray) => [...prevArray, "address3check"]);
            addressline3boolean = 1;
          } else if (element.name === "sloganset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("titlecheck");
            setArrayCheck((prevArray) => [...prevArray, "titlecheck"]);
            titleboolean = 1;
          } else if (element.name === "contactset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("contactcheck");
            setArrayCheck((prevArray) => [...prevArray, "contactcheck"]);
            contactboolean = 1;
          } else if (element.name === "emailset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("emailcheck");
            setArrayCheck((prevArray) => [...prevArray, "emailcheck"]);
            emailboolean = 1;
          } else if (element.name === "nameset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("namecheck");
            setArrayCheck((prevArray) => [...prevArray, "namecheck"]);
            nameboolean = 1;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
            arraycheck.push("vlcheck");
            setArrayCheck((prevArray) => [...prevArray, "vlcheck"]);
            vlboolean = 1;
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
            arraycheck.push("hlcheck");
            setArrayCheck((prevArray) => [...prevArray, "hlcheck"]);
            hlboolean = 1;
          }
        }
      });
    }
    return;
  };
  //Get list of organizations
  const fetchcards = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    // requestOptions
    await axios(`${NODE_API}/card/personal`, requestOptions)
      .then((res) => {
        if (res && res.data[0]) setDesign(res.data[0].front);
        else return;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }, [dataProvider, arrayCheck]);

  const handleInput = (e) => {
    const value = e.target.value;

    const fontFamilies = [
      "Serif",
      "Sans-serif",
      "Monospace",
      "Cursive",
      "Arial",
      "Helvetica",
      "Georgia",
      "Times New Roman",
      "Courier New",
      "Verdana",
      "Trebuchet MS",
      "Palatino",
      "Garamond",
      "Impact",
      "Comic Sans MS",
    ];

    if (
      [
        "nameset",
        "companynameset",
        "sloganset",
        "contactset",
        "emailset",
        "designationset",
        "empoyeeset",
        "addressset",
        "address2set",
        "address3set",
        "mydivvl",
        "mydivhl",
      ].includes(value)
    ) {
      setState((state) => ({
        ...state,
        getname: value,
      }));
      boldboolean = 0;
      italicboolean = 0;
      underlineboolean = 0;
    } else if (fontFamilies.includes(value)) {
      setState((state) => ({
        ...state,
        getfontstyle: value,
      }));

      if (state.getname) {
        document.getElementById(state.getname).style.fontFamily = value;
      }
    } else if (["bolder", "underline", "italic"].includes(value)) {
      setState((state) => ({
        ...state,
        getteststyle: value,
      }));

      if (state.getname) {
        const element = document.getElementById(state.getname);

        if (value === "underline") {
          if (underlineboolean === 0) {
            element.style.textDecoration = "underline";
            underlineboolean = 1;
          } else {
            element.style.textDecoration = "none";
            underlineboolean = 0;
          }
        } else if (value === "italic") {
          if (italicboolean === 0) {
            element.style.fontStyle = "italic";
            italicboolean = 1;
          } else {
            element.style.fontStyle = "normal";
            italicboolean = 0;
          }
        } else if (value === "bolder") {
          if (boldboolean === 0) {
            element.style.fontWeight = "900";
            boldboolean = 1;
          } else {
            element.style.fontWeight = "normal";
            boldboolean = 0;
          }
        }
      }
    }
  };

  const handleInputSize = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.width = size;
    } else if (state.getname && state.getname == "mydivhl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.height = size;
    } else if (state.getname) {
      var size = e.target.value + "px";
      document.getElementById(state.getname).style.fontSize = size;
    }
  };
  const handleInputcolor = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      document.getElementById(state.getname).style.backgroundColor =
        e.target.value;
    } else if (state.getname && state.getname == "mydivhl") {
      document.getElementById(state.getname).style.borderColor = e.target.value;
    } else if (state.getname) {
      document.getElementById(state.getname).style.color = e.target.value;
    }
  };

  const download_img = (el) => {
    var elementid = document.getElementById("nameset");
    var parentid = document.getElementById("mydivname");
    var namedata = getdetails(
      "nameset",
      "mydivname",
      parentid,
      elementid,
      nameboolean
    );

    elementid = document.getElementById("sloganset");
    parentid = document.getElementById("mydivslogan");
    var slogandata = getdetails(
      "sloganset",
      "mydivslogan",
      parentid,
      elementid,
      titleboolean
    );

    elementid = document.getElementById("contactset");
    parentid = document.getElementById("mydivcontact");
    var contactdata = getdetails(
      "contactset",
      "mydivcontact",
      parentid,
      elementid,
      contactboolean
    );

    elementid = document.getElementById("addressset");
    parentid = document.getElementById("mydivaddress");
    var addressdata = getdetails(
      "addressset",
      "mydivaddress",
      parentid,
      elementid,
      addressboolean
    );

    elementid = document.getElementById("address2set");
    parentid = document.getElementById("mydivaddress2");
    var addressdata2 = getdetails(
      "address2set",
      "mydivaddress2",
      parentid,
      elementid,
      addressline2boolean
    );

    elementid = document.getElementById("address3set");
    parentid = document.getElementById("mydivaddress3");
    var addressdata3 = getdetails(
      "address3set",
      "mydivaddress3",
      parentid,
      elementid,
      addressline3boolean
    );

    elementid = document.getElementById("emailset");
    parentid = document.getElementById("mydivemail");
    var emaildata = getdetails(
      "emailset",
      "mydivemail",
      parentid,
      elementid,
      emailboolean
    );

    elementid = document.getElementById("logo");
    parentid = document.getElementById("mydivlogo");
    var logodata = getdetails(
      "logo",
      "mydivlogo",
      parentid,
      elementid,
      imgboolean
    );

    elementid = document.getElementById("vlset");
    parentid = document.getElementById("mydivvl");
    var verticallinedata = getdetails(
      "vlset",
      "mydivvl",
      parentid,
      elementid,
      vlboolean
    );

    elementid = document.getElementById("hlset");
    parentid = document.getElementById("mydivhl");
    var horizontallinedata = getdetails(
      "hlset",
      "mydivhl",
      parentid,
      elementid,
      hlboolean
    );

    var jsonData = [];

    jsonData.push(namedata);
    jsonData.push(slogandata);
    jsonData.push(contactdata);
    jsonData.push(addressdata);
    jsonData.push(addressdata2);
    jsonData.push(addressdata3);
    jsonData.push(emaildata);
    jsonData.push(logodata);
    jsonData.push(verticallinedata);
    jsonData.push(horizontallinedata);

    const cardElement = document.querySelector(".card");

    domtoimage
      .toPng(cardElement, {
        useCORS: true,
        cacheBust: true,
      })
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        createcard(jsonData, img.src);
      })
      .catch((error) => {
        console.error("Screenshot failed:", error);
      });
  };

  function getdetails(
    elementname,
    elementparentname,
    parentid,
    elementid,
    disable
  ) {
    if (disable == 1) {
      if (elementname == "logo") {
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");
        var data = {
          name: elementname,
          isDisable: true,
          backgroundtemplate: backimage,
        };
      } else {
        var data = {
          name: elementname,
          isDisable: true,
        };
      }
      return data;
    } else {
      if (elementname == "logo") {
        let compStyles = window.getComputedStyle(parentid);
        var getImage = compStyles.getPropertyValue("background-image");
        if (getImage.includes("base64")) {
          var image = getImage
            .replace("url(", "")
            .replace(")", "")
            .replaceAll('"', "");
        } else {
          var image = null;
        }
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");
        var data = {
          backgroundtemplate: backimage,
          name: elementname,
          isDisable: false,
          value: image,
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            width: `${parentid.offsetWidth}px`,
          },
        };
        return data;
      } else if (elementname == "vlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            width: parentid.offsetWidth,
            backgroundColor: compStyles.getPropertyValue("background-color"),
          },
        };
        return data;
      } else if (elementname == "hlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            borderColor: compStyles.getPropertyValue("border-color"),
          },
        };
        return data;
      } else {
        let compStyles = window.getComputedStyle(elementid);
        var data = {
          name: elementname,
          isDisable: false,
          value: elementid.innerHTML,
          textStyle: compStyles.getPropertyValue("font-family"),
          fontStyle: {
            bold: compStyles.getPropertyValue("font-weight"),
            italic: compStyles.getPropertyValue("font-style"),
            underline: compStyles.getPropertyValue("text-decoration"),
          },
          fontSize: compStyles.getPropertyValue("font-size"),
          fontColor: compStyles.getPropertyValue("color"),
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
          },
        };
        return data;
      }
    }
  }

  async function createcard(cardData, img) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        cardData: cardData,
        img: img,
      }),
    };

    // requestOptions
    return axios(`${NODE_API}/card/personal`, requestOptions)
      .then(async (res) => {
        if (res.data.status && res.data.status === "Successfully Added") {
          notify(`Template successfully registered`, { type: "success" });
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }
  return (
    <div className="main">
      <SimpleForm toolbar={false} className="setting">
        <TextInput
          autoFocus
          id="cardnameupdate"
          source="cardname"
          label="Name on the card"
          formClassName={classes.name}
        />

        <TextInput
          source="cardtite"
          id="sloganupdate"
          label="Title on the card"
          formClassName={classes.name}
        />

        <TextInput
          id="emailupdate"
          source="cardemail"
          label="Personal Email"
          formClassName={classes.name}
        />
        <TextInput
          source="cardcontactno"
          label="Personal Contact Number"
          id="contactupdate"
          formClassName={classes.name}
        />

        <TextInput
          source="addresscard"
          label="Address Line 1"
          id="addressupdate"
          formClassName={classes.name}
        />
        <TextInput
          source="addressline2"
          label="Address Line 2"
          id="address2update"
          formClassName={classes.name}
        />
        <TextInput
          source="addressline3"
          label="Address Line 3"
          id="address3update"
          formClassName={classes.name}
        />

        <CheckboxGroupInput
          id="check"
          initialValue={arraycheck}
          formClassName={classes.name}
          source="disabled"
          label={
            <span style={{ fontWeight: "bold", color: "black", fontSize: 24 }}>
              Show/Hide
            </span>
          }
          choices={[
            { id: "namecheck", name: "name" },
            { id: "titlecheck", name: "title" },
            { id: "contactcheck", name: "contact" },
            { id: "emailcheck", name: "email" },
            { id: "imagecheck", name: "image" },
            { id: "addresscheck", name: "address line 1" },
            { id: "address2check", name: "address line 2" },
            { id: "address3check", name: "address line 3" },
            { id: "vlcheck", name: "horizontal line" },
            { id: "hlcheck", name: "vertical line" },
          ]}
        />

        <ImageInput
          id="upload"
          source="cardlogo"
          label={
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: 24,
                marginLeft: 20,
              }}
            >
              Image
            </span>
          }
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a logo to upload, or click to select it.logo should not greater than 50kb"
        ></ImageInput>

        {/* <Typography variant="h7" gutterBottom>
          Background Templates
        </Typography> */}
        {/* <input type="checkbox" id="topping" name="topping" value="Paneer" />Paneer */}
        {/* <span
          style={{
            fontWeight: "bold",
            color: "black",
            fontSize: 20,
            marginLeft: 18,
          }}
        >
          Background Templates
        </span> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <div
            style={{
              color: "black",
              fontSize: 20,
              marginLeft: 18,
              fontWeight: "bold",
            }}
          >
            Plain Background Templates
          </div>
          <button
            onClick={() => setShowPlain(!showPlain)}
            style={{
              marginLeft: 10,
              cursor: "pointer",
              background: "none",
              border: "none",
              color: "blue",
              textDecoration: "underline",
              fontSize: 14,
            }}
          >
            {/* {showPlain ? "Hide" : "Show"} */}
            {showPlain ? (
              <ExpandLess style={{ width: 40, height: 40 }} />
            ) : (
              <ExpandMore style={{ width: 40, height: 40 }} />
            )}
          </button>
        </div>
        {showPlain && templateData.plain && (
          <div className="backgrounds" style={{ width: "100%" }}>
            {templateData.plain.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Plain ${index}`}
                style={{
                  border:
                    activeBgType === "plain" && activeBgIndex === index
                      ? "2px solid blue"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveBgType("plain");
                  setActiveBgIndex(index);
                }}
                width="100"
              />
            ))}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 20,
              marginLeft: 18,
              fontWeight: "bold",
            }}
          >
            Graphic Background Templates
          </span>
          <button
            onClick={() => setShowGraphic(!showGraphic)}
            style={{
              marginLeft: 10,
              cursor: "pointer",
              background: "none",
              border: "none",
              color: "blue",
              textDecoration: "underline",
              fontSize: 14,
            }}
          >
            {showGraphic ? (
              <ExpandLess style={{ width: 40, height: 40 }} />
            ) : (
              <ExpandMore style={{ width: 40, height: 40 }} />
            )}
          </button>
        </div>
        {showGraphic && templateData && (
          <div className="backgrounds" style={{ width: "100%" }}>
            {templateData.graphic.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Graphic ${index}`}
                className={
                  activeBgType === "graphic" && activeBgIndex === index
                    ? "active"
                    : ""
                }
                style={{
                  border:
                    activeBgType === "graphic" && activeBgIndex === index
                      ? "2px solid blue"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveBgType("graphic");
                  setActiveBgIndex(index);
                }}
                width="100"
              />
            ))}
          </div>
        )}
        <ImageInput
          id="BackgroundTemplates"
          source="BackgroundTemplates"
          label={
            <span
              style={{
                fontWeight: 600,
                color: "black",
                fontSize: 18,
                marginLeft: 20,
              }}
            >
              Custom Background Templates
            </span>
          }
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a template image to upload, or click to select it.image should not greater than 50kb"
        ></ImageInput>
      </SimpleForm>
      <div className="setting">
        <div
          className="card"
          id="card"
          style={{
            borderRadius: "0px",
            // backgroundImage:
            //   activeBgType === "plain" && templateData?.plain?.[activeBgIndex]
            //     ? `url('${templateData.plain[activeBgIndex]}')`
            //     : activeBgType === "graphic" &&
            //       templateData?.graphic?.[activeBgIndex]
            //     ? `url('${templateData.graphic[activeBgIndex]}')`
            //     : "none",
            backgroundImage:
              activeBgIndex !== null
                ? `url('${
                    activeBgType === "plain"
                      ? templateData?.plain?.[activeBgIndex]
                      : templateData?.graphic?.[activeBgIndex]
                  }')`
                : `url('${defaultBgTemplate}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span
            id="mydivlogo"
            className="mydivlogo"
            style={{
              filter:
                "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)",
              top: "20px",
              left: "26px",
            }}
          >
            <div className="logo" id="logo"></div>
          </span>
          <span id="mydivname" style={{ top: "43px", left: "192px" }}>
            <p className="name" id="nameset">
              Your Name
            </p>
          </span>
          <span id="mydivslogan" style={{ top: "20px", left: "190px" }}>
            <p className="slogan" id="sloganset">
              Title on card
            </p>
          </span>
          <span id="mydivcontact" style={{ top: "110px", left: "40px" }}>
            <p className="contact" id="contactset">
              Contact Number
            </p>
          </span>
          <span id="mydivemail" style={{ top: "130px", left: "40px" }}>
            <p className="email" id="emailset">
              Email Address
            </p>
          </span>
          <span id="mydivaddress" style={{ top: "230px", left: "18px" }}>
            <p className="address" id="addressset">
              Address Line 1
            </p>
          </span>
          <span id="mydivaddress2" style={{ top: "260px", left: "18px" }}>
            <p className="address2" id="address2set">
              Address Line 2
            </p>
          </span>
          <span id="mydivaddress3" style={{ bottom: "15px", right: "40px" }}>
            <p className="address3" id="address3set">
              Address Line 3
            </p>
          </span>

          <span id="mydivhl" style={{ bottom: "10px", left: "230px" }}>
            <hr className="hl" id="hlset" />
          </span>
          <span id="mydivvl" style={{ bottom: "70px", right: "20px" }}>
            <p className="vl" id="vlset"></p>
          </span>
        </div>
        <SimpleForm save={download_img}>
          <SelectInput
            id="getforstyle"
            onChange={handleInput}
            source="Input Fields"
            defaultValue={"nameset"}
            formClassName={classes.displayblock}
            choices={[
              { id: "nameset", name: "name" },
              { id: "sloganset", name: "title" },
              { id: "contactset", name: "contact" },
              { id: "emailset", name: "email" },
              { id: "addressset", name: "address line 1" },
              { id: "address2set", name: "address line 2" },
              { id: "address3set", name: "address line 3" },
              { id: "mydivvl", name: "horizontal line" },
              { id: "mydivhl", name: "vertical line" },
            ]}
          />
          <SelectInput
            id="gettextstyle"
            onChange={handleInput}
            source="TextStyle"
            formClassName={classes.name}
            choices={[
              { id: "Serif", name: "Serif" },
              { id: "Sans-serif", name: "Sans-serif" },
              { id: "Monospace", name: "Monospace" },
              { id: "Cursive", name: "Cursive" },
              { id: "Arial", name: "Arial" },
              { id: "Georgia", name: "Georgia" },
              { id: "Times New Roman", name: "Times New Roman" },
              { id: "Courier New", name: "Courier New" },
              { id: "Trebuchet MS", name: "Trebuchet MS" },
              { id: "Palatino", name: "Palatino" },
              { id: "Garamond", name: "Garamond" },
              { id: "Impact", name: "Impact" },
            ]}
          />
          <SelectInput
            onChange={handleInput}
            source="Font"
            choices={[
              { id: "bolder", name: "bold" },
              { id: "italic", name: "italic" },
              { id: "underline", name: "underline" },
            ]}
            formClassName={classes.displayblock}
          />
          <TextInput
            onChange={handleInputSize}
            formClassName={classes.name}
            id="fontupdate"
            source="font"
            label="Enter element size in px"
            type="number"
          />

          <TextInput
            onChange={handleInputcolor}
            id="colorupdate"
            source="colour"
            label="Click to Select colour"
            formClassName={classes.leftCol}
            type="color"
          />
        </SimpleForm>
      </div>

      <a href="#" id="link" download="visiting card.png"></a>
    </div>
  );
};
const BackView = ({ templateData }) => {
  const [state, setState] = useState({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [showPlain, setShowPlain] = useState(true);
  const [showGraphic, setShowGraphic] = useState(false);

  const [activeBgType, setActiveBgType] = useState("plain");
  const [activeBgIndex, setActiveBgIndex] = useState(null);
  const [defaultBgTemplate, setDefaultBgTemplate] = useState(null);

  useEffect(() => {
    //changing the background
    const bgs = document.querySelectorAll(".backgrounds img");
    const card = document.querySelector(".card");
    let activeBg = 0; //default background

    setState((state) => ({
      ...state,

      getname: "nameset",
    }));

    bgs.forEach((item, i) => {
      item.addEventListener("click", () => {
        bgs[activeBg].classList.remove("active");
        item.classList.add("active");
        card.style.backgroundImage = `url('${item.src}')`;
        activeBg = i;
      });
    });

    // setting up logo
    const uploadBtn = document.querySelector("#upload");
    const mydivlogo = document.querySelector(".mydivlogo");
    uploadBtn.addEventListener("change", () => {
      if (uploadBtn.files && uploadBtn.files[0]) {
        let reader = new FileReader(); // init the file reader
        reader.addEventListener("load", () => {
          mydivlogo.style.backgroundImage = `url('${reader.result}')`;
        });
        reader.readAsDataURL(uploadBtn.files[0]);
      }
    });
    // setting background image
    const BackgroundTemplates = document.querySelector("#BackgroundTemplates");
    const BackgroundTemplatescard = document.querySelector("#card");
    BackgroundTemplates.addEventListener("change", () => {
      if (BackgroundTemplates.files && BackgroundTemplates.files[0]) {
        let reader = new FileReader(); // init the file reader
        reader.addEventListener("load", () => {
          BackgroundTemplatescard.style.backgroundImage = `url('${reader.result}')`;
        });
        reader.readAsDataURL(BackgroundTemplates.files[0]);
      }
    });
    //setting up name
    const cardname = document.querySelector("#cardnameupdate");
    cardname.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("nameset").innerHTML = text;
    });

    //setting up slogan
    const sloganupdate = document.querySelector("#sloganupdate");

    sloganupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("sloganset").innerHTML = text;
    });

    //hide and unhide fields
    const check = document.querySelector("#check");
    check.addEventListener("change", (e) => {
      if (e.target.value == "namecheck") {
        if (bnameboolean === 0) {
          document.getElementById("nameset").style.visibility = "hidden";
          bnameboolean = 1;
        } else {
          document.getElementById("nameset").style.visibility = "visible";
          bnameboolean = 0;
        }
      }
      if (e.target.value == "titlecheck") {
        if (btitleboolean === 0) {
          document.getElementById("sloganset").style.visibility = "hidden";
          btitleboolean = 1;
        } else {
          document.getElementById("sloganset").style.visibility = "visible";
          btitleboolean = 0;
        }
      }

      if (e.target.value == "imagecheck") {
        if (bimgboolean === 0) {
          document.getElementById("mydivlogo").style.visibility = "hidden";
          bimgboolean = 1;
        } else {
          document.getElementById("mydivlogo").style.visibility = "visible";
          bimgboolean = 0;
        }
      }

      if (e.target.value == "vlcheck") {
        if (bvlboolean === 0) {
          document.getElementById("mydivvl").style.visibility = "hidden";
          bvlboolean = 1;
        } else {
          document.getElementById("mydivvl").style.visibility = "visible";
          bvlboolean = 0;
        }
      }
      if (e.target.value == "hlcheck") {
        if (bhlboolean === 0) {
          document.getElementById("mydivhl").style.visibility = "hidden";
          bhlboolean = 1;
        } else {
          document.getElementById("mydivhl").style.visibility = "visible";
          bhlboolean = 0;
        }
      }
    });

    //Make the DIV element draggagle:
    dragElement(document.getElementById("mydivname"));
    dragElement(document.getElementById("mydivslogan"));
    dragElement(document.getElementById("mydivvl"));
    dragElement(document.getElementById("mydivhl"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    // window.onload = function() {
    initDragElement();
    initResizeElement();
    //   };

    function initDragElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("mydivlogo");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("logo");
        if (headerItems.length === 1) {
          return headerItems[0];
        }
        return null;
      }
    }

    function initResizeElement() {
      var popups = document.getElementsByClassName("mydivlogo");
      var element = null;
      var startX, startY, startWidth, startHeight;
      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];
        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;
        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;
        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;
        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    }
    fetchcards();
  }, []);
  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";
          document.getElementById(element.position.name).style.top = topvalue;
          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;
          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width;
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
          } else if (element.name != "logo") {
            document.getElementById(element.name).innerHTML = element.value;
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
          } else {
            if (element.backgroundtemplate != null)
              setDefaultBgTemplate(element.backgroundtemplate);

            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null)
              document.getElementById(
                "mydivlogo"
              ).style.backgroundImage = `url(${element.value})`;
            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
          }
        } else {
          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            arraycheck.push("imagecheck");

            bimgboolean = 1;
          } else if (element.name === "sloganset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("titlecheck");

            btitleboolean = 1;
          } else if (element.name === "nameset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("namecheck");

            bnameboolean = 1;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
            arraycheck.push("vlcheck");

            bvlboolean = 1;
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
            arraycheck.push("hlcheck");

            bhlboolean = 1;
          }
        }
      });
    }
    return;
  };
  //Get list of organizations
  const fetchcards = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    // requestOptions
    await axios(`${NODE_API}/card/personal`, requestOptions)
      .then((res) => {
        if (res && res.data[0]) setDesign(res.data[0].back);
        else return;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }, [dataProvider]);
  const handleInput = (e) => {
    if (
      e.target.value == "nameset" ||
      e.target.value == "sloganset" ||
      e.target.value == "mydivvl" ||
      e.target.value == "mydivhl"
    ) {
      setState((state) => ({
        ...state,

        getname: e.target.value,
      }));
      bboldboolean = 0;
      bitalicboolean = 0;
      bunderlineboolean = 0;
    } else if (
      e.target.value == "Serif" ||
      e.target.value == "Sans-serif" ||
      e.target.value == "Monospace" ||
      e.target.value == "Cursive"
    ) {
      setState((state) => ({
        ...state,

        getfontstyle: e.target.value,
      }));
      if (state.getname) {
        document.getElementById(state.getname).style.fontFamily =
          e.target.value;
      }
    } else if (
      e.target.value == "bolder" ||
      e.target.value == "underline" ||
      e.target.value == "italic"
    ) {
      setState((state) => ({
        ...state,

        getteststyle: e.target.value,
      }));
      if (state.getname) {
        if (e.target.value == "underline") {
          if (bunderlineboolean === 0) {
            bunderlineboolean = 1;
            document.getElementById(state.getname).style.textDecoration =
              e.target.value;
          } else {
            document.getElementById(state.getname).style.textDecoration =
              "none";
            bunderlineboolean = 0;
          }
        } else if (e.target.value == "italic") {
          if (bitalicboolean === 0) {
            bitalicboolean = 1;
            document.getElementById(state.getname).style.fontStyle =
              e.target.value;
          } else {
            bitalicboolean = 0;
            document.getElementById(state.getname).style.fontStyle = "normal";
          }
        } else if (e.target.value == "bolder") {
          if (bboldboolean === 0) {
            document.getElementById(state.getname).style.fontWeight = "900";
            bboldboolean = 1;
          } else {
            document.getElementById(state.getname).style.fontWeight = "normal";
            bboldboolean = 0;
          }
        }
      }
    }
  };

  const handleInputSize = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      var size = e.target.value + "%";

      document.getElementById(state.getname).style.width = size;
    } else if (state.getname && state.getname == "mydivhl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.height = size;
    } else if (state.getname) {
      var size = e.target.value + "px";
      document.getElementById(state.getname).style.fontSize = size;
    }
  };
  const handleInputcolor = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      document.getElementById(state.getname).style.backgroundColor =
        e.target.value;
    } else if (state.getname && state.getname == "mydivhl") {
      document.getElementById(state.getname).style.borderColor = e.target.value;
    } else if (state.getname) {
      document.getElementById(state.getname).style.color = e.target.value;
    }
  };

  const download_img = (el) => {
    var elementid = document.getElementById("nameset");
    var parentid = document.getElementById("mydivname");
    var namedata = getdetails(
      "nameset",
      "mydivname",
      parentid,
      elementid,
      bnameboolean
    );

    elementid = document.getElementById("sloganset");
    parentid = document.getElementById("mydivslogan");
    var slogandata = getdetails(
      "sloganset",
      "mydivslogan",
      parentid,
      elementid,
      btitleboolean
    );

    elementid = document.getElementById("logo");
    parentid = document.getElementById("mydivlogo");
    var logodata = getdetails(
      "logo",
      "mydivlogo",
      parentid,
      elementid,
      bimgboolean
    );

    elementid = document.getElementById("vlset");
    parentid = document.getElementById("mydivvl");
    var verticallinedata = getdetails(
      "vlset",
      "mydivvl",
      parentid,
      elementid,
      bvlboolean
    );

    elementid = document.getElementById("hlset");
    parentid = document.getElementById("mydivhl");
    var horizontallinedata = getdetails(
      "hlset",
      "mydivhl",
      parentid,
      elementid,
      bhlboolean
    );

    var jsonData = [];

    jsonData.push(namedata);
    jsonData.push(slogandata);
    jsonData.push(logodata);
    jsonData.push(verticallinedata);
    jsonData.push(horizontallinedata);

    const cardElement = document.querySelector(".card");

    domtoimage
      .toPng(cardElement)
      .then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        img.alt = "Captured Card";

        createcard(jsonData, img.src);

        // Alternatively, to download the image, uncomment the lines below:
        // const link = document.createElement("a");
        // link.href = dataUrl;
        // link.download = "card-image.png";
        // link.click();
      })
      .catch((error) => {
        console.error("Failed to capture card as image", error);
      });
  };

  function getdetails(
    elementname,
    elementparentname,
    parentid,
    elementid,
    disable
  ) {
    if (disable == 1) {
      if (elementname == "logo") {
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          name: elementname,
          isDisable: true,
          backgroundtemplate: backimage,
        };
      } else {
        var data = {
          name: elementname,
          isDisable: true,
        };
      }
      return data;
    } else {
      if (elementname == "logo") {
        let compStyles = window.getComputedStyle(parentid);
        var getImage = compStyles.getPropertyValue("background-image");

        if (getImage.includes("base64")) {
          var image = getImage
            .replace("url(", "")
            .replace(")", "")
            .replaceAll('"', "");
        } else {
          var image = null;
        }
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");
        var data = {
          backgroundtemplate: backimage,
          name: elementname,
          isDisable: false,
          value: image,
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            width: `${parentid.offsetWidth}px`,
          },
        };
        return data;
      } else if (elementname == "vlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            width: parentid.offsetWidth,
            backgroundColor: compStyles.getPropertyValue("background-color"),
          },
        };
        return data;
      } else if (elementname == "hlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            borderColor: compStyles.getPropertyValue("border-color"),
          },
        };
        return data;
      } else {
        let compStyles = window.getComputedStyle(elementid);
        var data = {
          name: elementname,
          isDisable: false,
          value: elementid.innerHTML,
          textStyle: compStyles.getPropertyValue("font-family"),
          fontStyle: {
            bold: compStyles.getPropertyValue("font-weight"),
            italic: compStyles.getPropertyValue("font-style"),
            underline: compStyles.getPropertyValue("text-decoration"),
          },
          fontSize: compStyles.getPropertyValue("font-size"),
          fontColor: compStyles.getPropertyValue("color"),
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
          },
        };
        return data;
      }
    }
  }

  async function createcard(cardData, img) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        cardData: cardData,
        img: img,
      }),
    };

    // requestOptions
    return axios(`${NODE_API}/card/personal/back`, requestOptions)
      .then(async (res) => {
        if (res.data.status && res.data.status === "Successfully Added") {
          notify(`Template successfully registered`, { type: "success" });
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }
  return (
    <div className="main">
      <SimpleForm toolbar={false} className="setting">
        <TextInput
          autoFocus
          id="cardnameupdate"
          source="cardname"
          label="Text 1"
          formClassName={classes.name}
        />
        <TextInput
          source="cardtite"
          id="sloganupdate"
          label="Text 2"
          formClassName={classes.name}
        />

        <CheckboxGroupInput
          id="check"
          initialValue={arraycheck}
          formClassName={classes.name}
          source="disabled"
          label={
            <span style={{ fontWeight: "bold", color: "black", fontSize: 24 }}>
              Show/Hide
            </span>
          }
          choices={[
            { id: "namecheck", name: "Text 1" },
            { id: "titlecheck", name: "Text 2" },
            { id: "imagecheck", name: "image" },
            { id: "vlcheck", name: "horizontal line" },
            { id: "hlcheck", name: "vertical line" },
          ]}
        />
        <ImageInput
          id="upload"
          source="cardlogo"
          label={
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: 24,
                marginLeft: 20,
              }}
            >
              Image
            </span>
          }
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a logo to upload, or click to select it.logo should not greater than 50kb"
        ></ImageInput>

        <span
          style={{
            fontWeight: "bold",
            color: "black",
            fontSize: 20,
            marginLeft: 18,
          }}
        >
          Background Templates
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <div style={{ color: "black", fontSize: 14, marginLeft: 18 }}>
            Plain Background Templates
          </div>
          <button
            onClick={() => setShowPlain(!showPlain)}
            style={{
              marginLeft: 10,
              cursor: "pointer",
              background: "none",
              border: "none",
              color: "blue",
              textDecoration: "underline",
              fontSize: 14,
            }}
          >
            {/* {showPlain ? "Hide" : "Show"} */}
            {showPlain ? (
              <ExpandLess style={{ width: 40, height: 40 }} />
            ) : (
              <ExpandMore style={{ width: 40, height: 40 }} />
            )}
          </button>
        </div>
        {showPlain && templateData.plain && (
          <div className="backgrounds" style={{ width: "100%" }}>
            {templateData.plain.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Plain ${index}`}
                style={{
                  border:
                    activeBgType === "plain" && activeBgIndex === index
                      ? "2px solid blue"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveBgType("plain");
                  setActiveBgIndex(index);
                }}
                width="100"
              />
            ))}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <span style={{ color: "black", fontSize: 14, marginLeft: 18 }}>
            Graphic Background Templates
          </span>
          <button
            onClick={() => setShowGraphic(!showGraphic)}
            style={{
              marginLeft: 10,
              cursor: "pointer",
              background: "none",
              border: "none",
              color: "blue",
              textDecoration: "underline",
              fontSize: 14,
            }}
          >
            {showGraphic ? (
              <ExpandLess style={{ width: 40, height: 40 }} />
            ) : (
              <ExpandMore style={{ width: 40, height: 40 }} />
            )}
          </button>
        </div>
        {showGraphic && templateData && (
          <div className="backgrounds" style={{ width: "100%" }}>
            {templateData.graphic.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Graphic ${index}`}
                className={
                  activeBgType === "graphic" && activeBgIndex === index
                    ? "active"
                    : ""
                }
                style={{
                  border:
                    activeBgType === "graphic" && activeBgIndex === index
                      ? "2px solid blue"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveBgType("graphic");
                  setActiveBgIndex(index);
                }}
                width="100"
              />
            ))}
          </div>
        )}

        <ImageInput
          id="BackgroundTemplates"
          source="BackgroundTemplates"
          label={
            <span
              style={{
                fontWeight: 600,
                color: "black",
                fontSize: 18,
                marginLeft: 20,
              }}
            >
              Custom Background Templates
            </span>
          }
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a template image to upload, or click to select it.image should not greater than 50kb"
        ></ImageInput>
      </SimpleForm>
      <div className="setting">
        <div
          className="card"
          id="card"
          style={{
            borderRadius: "0px",
            backgroundImage:
              activeBgIndex !== null
                ? `url('${
                    activeBgType === "plain"
                      ? templateData?.plain?.[activeBgIndex]
                      : templateData?.graphic?.[activeBgIndex]
                  }')`
                : `url('${defaultBgTemplate}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span
            id="mydivlogo"
            className="mydivlogo"
            style={{
              filter:
                "drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white)",
              top: "20px",
              left: "26px",
            }}
          >
            <div className="logo" id="logo"></div>
          </span>
          <span id="mydivname" style={{ top: "20px", left: "210px" }}>
            <p className="name" id="nameset">
              Text 1
            </p>
          </span>
          <span id="mydivslogan" style={{ top: "45px", left: "210px" }}>
            <p className="slogan" id="sloganset">
              Text 2
            </p>
          </span>
          <span id="mydivhl" style={{ bottom: "10px", left: "230px" }}>
            <hr className="hl" id="hlset" />
          </span>
          <span id="mydivvl" style={{ bottom: "70px", right: "20px" }}>
            <p className="vl" id="vlset"></p>
          </span>
        </div>
        <SimpleForm save={download_img}>
          <SelectInput
            id="getforstyle"
            onChange={handleInput}
            source="stylename"
            defaultValue={"nameset"}
            formClassName={classes.displayblock}
            choices={[
              { id: "nameset", name: "Text 1" },
              { id: "sloganset", name: "Text 2" },
              { id: "mydivvl", name: "horizontal line" },
              { id: "mydivhl", name: "vertical line" },
            ]}
          />
          <SelectInput
            id="gettextstyle"
            onChange={handleInput}
            source="TextStyle"
            formClassName={classes.name}
            choices={[
              { id: "Serif", name: "Serif	" },
              { id: "Sans-serif", name: "Sans-serif" },
              { id: "Monospace", name: "Monospace" },
              { id: "Cursive", name: "Cursive" },
            ]}
          />

          <SelectInput
            onChange={handleInput}
            source="Font"
            choices={[
              { id: "bolder", name: "bold" },
              { id: "italic", name: "italic" },
              { id: "underline", name: "underline" },
            ]}
            formClassName={classes.displayblock}
          />

          <TextInput
            onChange={handleInputSize}
            formClassName={classes.name}
            id="fontupdate"
            source="font"
            label="Enter element size in px"
            type="number"
          />

          <TextInput
            onChange={handleInputcolor}
            id="colorupdate"
            source="colour"
            label="Click to Select colour"
            formClassName={classes.leftCol}
            type="color"
          />
        </SimpleForm>
      </div>
      <a href="#" id="link" download="visiting card.png"></a>
    </div>
  );
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const DesignCard = (props) => {
  const [state, setState] = React.useState({
    checkedC: true,
  });

  const [templateData, setTemplateData] = useState(0);

  useEffect(() => {
    const fetchImageCount = async () => {
      try {
        const response = await fetch(`${NODE_API}/card-template`);
        const data = await response.json();

        setTemplateData(data);
      } catch (error) {
        console.error("Error fetching image count:", error);
      }
    };

    fetchImageCount();
  }, []);

  const handleChange = (event) => {
    if (viewDesign === 0) {
      viewDesign = 1;
    } else {
      viewDesign = 0;
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <FormGroup style={{ display: "block", margin: "5px 100px 10px 5px" }}>
        <Typography
          component="div"
          style={{ width: "fit-content", float: "right" }}
        >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Backview</Grid>
            <Grid item>
              <AntSwitch
                checked={state.checkedC}
                onChange={handleChange}
                name="checkedC"
              />
            </Grid>
            <Grid item>Frontview</Grid>
          </Grid>
        </Typography>
      </FormGroup>
      {viewDesign === 0 ? (
        <FrontView templateData={templateData} />
      ) : (
        <BackView templateData={templateData} />
      )}
    </>
  );
};

export default DesignCard;
