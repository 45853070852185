import React, { useState } from "react";
import { TextInput, SimpleForm, required, useNotify, useRedirect } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { NODE_API } from '../../../../config';
import { useRefresh } from 'react-admin';

export const styles = {

    refer: { width: 544 },
    city: { width: 544 },
    address: { width: 544 },
    singleCol: { marginTop: '2em', marginBottom: '2em' },

};

const useStyles = makeStyles(styles);

const validatecity = [required()]

const AddToOrganization = (props) => {

    const [state, setState] = useState({});
    const notify = useNotify();
    const classes = useStyles();
    const refresh = useRefresh();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    var locationCity = [];


    async function addLocation() {
        let user = JSON.parse(sessionStorage.getItem('user'));

        let token;

        if (user && user.validator) {
            token = user.validator
        } else {
            token = {}
        }

        const requestOptions = {
            method: 'PUT',
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        return axios(`${NODE_API}/organization/location?id=${state.locationId}&organizationID=${state.organizationID}`, requestOptions)

            .then(async res => {

                if (res && res.status === 208) {
                    notify(`Organization already register`, { type: 'warning' })
                }
                else if (res && res.status === 200) {
                    notify(`Registered to organization successfully`, { type: 'success' })
                    refresh()
                }
                else if (res && res.status === 204) {
                    notify(`Enter Valid Refer Code`, { type: 'warning' })
                }
                else {
                    notify(`Something went wrong try again later`, { type: 'warning' })
                }

                return res;
            }).catch(e => {
                notify(`Something went wrong try again later`, { type: 'warning' })
            });
    }


    const handleInput = (value) => {

        setState(state => ({

            ...state,

            locationId: value.id,

            organizationID: value.orgId


        }))

    }


    const onsubmit = async (data) => {

        await addLocation(data.referCode)

    };

    const { referCode, LocationList } = props


    LocationList.map(val => {
        locationCity.push({ value: val.city, label: val.city, id: val.id, orgId: val.organization_id })
    })



    return isXSmall ? (

        <SimpleForm save={onsubmit}>

            <Typography variant="h6" gutterBottom>
                Add Organization
            </Typography>

            <TextInput
                disabled
                autoFocus
                source="referCode"
                label="Enter Organization Refer Code"
                formClassName={classes.city}
                validate={validatecity}
            />
            <Select
                placeholder='Location List'
                source="locationList"
                label="Location List"
                options={locationCity}
                onChange={handleInput}
            />
        </SimpleForm>

    ) : isSmall ? (

        <SimpleForm save={onsubmit}>

            <Typography variant="h6" gutterBottom>
                Add Organization
            </Typography>

            <TextInput
                disabled
                autoFocus
                source="referCode"
                label="Enter Organization Refer Code"
                formClassName={classes.city}
                validate={validatecity}
            />
            <Select
                placeholder='Location List'
                source="locationList"
                label="Location List"
                options={locationCity}
                onChange={handleInput}
            />
        </SimpleForm>

    ) :
        (
            <SimpleForm save={onsubmit}>

                <Typography variant="h6" gutterBottom>
                    Add Organization
                </Typography>

                <TextInput
                    disabled
                    defaultValue={referCode}
                    autoFocus
                    source="referCode"
                    fullWidth={true}
                    label="Enter Organization Refer Code"
                    formClassName={classes.refer}
                    validate={validatecity}
                />
                <Select
                    placeholder='Location List'
                    source="locationList"
                    label="Location List"
                    options={locationCity}
                    onChange={handleInput}
                />
            </SimpleForm>
        );
};



export default AddToOrganization;