// import "react-app-polyfill/ie11";
// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import "./index.css";

// ReactDOM.render(<App />, document.getElementById("root"));



import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/redux/store'

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>

      <App />

    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);


