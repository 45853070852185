import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import UserMenu from './UserMenu';
import CorporateAdminMenu from './CorporateAdminMenu';
import { darkTheme, lightTheme } from './themes';
import SuperAdminMenu from './SuperAdminMenu';
const CustomSidebar = props => <Sidebar {...props} size={200} />;

export default props => {
    const theme = useSelector(state =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;
    if (user && user.validator) {
        token = user.validator
    } else {
        token = {}
    }
    return (
        <>
            {
                user.type === 'SUPER_ADMIN' ?
                    (<Layout
                        {...props}
                        appBar={AppBar}
                        sidebar={CustomSidebar}
                        menu={SuperAdminMenu}
                        theme={theme}
                    />) :
                    (user.type === 'CORPORATE_ADMIN' ?
                        (
                            <Layout
                                {...props}
                                appBar={AppBar}
                                sidebar={CustomSidebar}
                                menu={CorporateAdminMenu}
                                theme={theme}
                            />
                        ) : (
                            <Layout
                                {...props}
                                appBar={AppBar}
                                sidebar={CustomSidebar}
                                menu={UserMenu}
                                theme={theme}
                            />
                        )
                    )
            }
        </>
    );
};
