import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
    Datagrid,
    EmailField,
    NumberField,
    TextField,
    useDataProvider,
    useVersion,
} from "react-admin";
import {
    makeStyles,
    useMediaQuery,
    Divider,
    Tabs,
    Tab
} from "@material-ui/core";

import MobileGrid from "./MobileGrid";


const useDatagridStyles = makeStyles({
    total: { fontWeight: "bold" },
    centerText: {
        textAlign: 'center',
    },
});



class TabbedDatagrid extends React.Component {

    constructor(props) {
        super(props)
        this.state = { T: [], P: [], R: [] };
    }

    tabs = [
        { id: "P", name: "Pending Requests" },
        { id: "T", name: "Accepted Requests" },
        { id: "R", name: "Rejected Requests" }
    ];


    static getDerivedStateFromProps(props, state) {

        const { data, filterValues } = props

        const obj = props.data
        var map = Object.keys(obj);

        var idsStatus = []

        if (data) {

            map.forEach(ele => {

                if (filterValues.is_approved === data[`${ele}`].is_approved) {

                    idsStatus.push(data[`${ele}`].id)

                }

            })

            return { [props.filterValues.is_approved]: idsStatus };

        } else {
            return ('No Requests')
        }
    }


    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;

        setFilters({ ...filterValues, is_approved: value });
    };

    render() {

        const { isXSmall, classes, role, filterValues, ...props } = this.props;

        return (
            <>
                {role == 'LOCATION_ADMIN' ?
                    <Fragment>
                        <Tabs
                            variant="fullWidth"
                            centered
                            value={filterValues.is_approved}
                            indicatorColor="primary"
                            onChange={this.handleChange}>
                            {this.tabs.map(choice => (
                                <Tab key={choice.id} label={choice.name} value={choice.id} />
                            ))}
                        </Tabs>
                        <Divider />

                        <>

                            {isXSmall ?
                                (<MobileGrid {...props} ids={this.state[filterValues.is_approved]} />) :
                                (<div className="text-center">

                                    {filterValues.is_approved === "T" ?
                                        (
                                            this.state.T && this.state.T.length === 0 ?
                                                <h5 className="text-center m-4">No Records</h5> :

                                                <Datagrid
                                                    {...props}
                                                    ids={this.state.T}
                                                    optimized
                                                    rowClick="edit"
                                                    hasBulkActions={false}
                                                    bulkActionButtons="false"
                                                    source='T'
                                                    className="text-center"
                                                >

                                                    <NumberField className={classes.centerText} source="employeeid" label="Employee ID" />
                                                    <TextField source="contactnumber" label="Phone" />
                                                    <TextField source="name" label="Name" />
                                                    <EmailField source="email" label="Email" />
                                                    <TextField source="designation" label="Designation" />
                                                    <TextField source="locname" label="location" />
                                                    <TextField source="organizationname" label="Organization" />


                                                </Datagrid>
                                        ) : <></>
                                    }
                                    {filterValues.is_approved === "P" ?
                                        <div className="text-center">

                                            {this.state.P && this.state.P.length === 0 ?
                                                <h5 className="text-center m-4">No Records</h5> :
                                                <div className="text-center">
                                                    <Datagrid {...props}
                                                        ids={this.state.P}
                                                        rowClick="edit"
                                                        className="text-center"
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <NumberField className={`${classes.centerText} text-center`} source="employeeid" label="Employee ID" />
                                                        <TextField className={classes.centerText} source="contactnumber" label="Phone" />
                                                        <TextField source="name" label="Name" />
                                                        <EmailField source="email" label="Email" />
                                                        <TextField source="designation" label="Designation" />
                                                        <TextField source="locname" label="location" />
                                                        <TextField source="organizationname" label="Organization" />

                                                    </Datagrid>
                                                </div>}
                                        </div> : <></>
                                    }
                                    {filterValues.is_approved === "R" ?
                                        (
                                            this.state.R && this.state.R.length === 0 ?
                                                <h5 className="text-center m-4">No Records</h5> :
                                                <Datagrid {...props}
                                                    ids={this.state.R}
                                                    hasBulkActions={false}
                                                    className="text-center"
                                                    rowClick="edit"
                                                >
                                                    <NumberField source="employeeid" label="Employee ID" />
                                                    <TextField source="contactnumber" label="Phone" />
                                                    <TextField source="name" label="Name" />
                                                    <EmailField source="email" label="Email" />
                                                    <TextField source="designation" label="Designation" />
                                                    <TextField source="locname" label="location" />
                                                    <TextField source="organizationname" label="Organization" />

                                                </Datagrid>
                                        ) : <></>
                                    }
                                </div>)}

                        </>

                    </Fragment>
                    : (role == 'CORPORATE_ADMIN' ?
                        <Fragment>
                            <Tabs
                                variant="fullWidth"
                                centered
                                value={filterValues.is_approved}
                                indicatorColor="primary"
                                onChange={this.handleChange}>
                                {this.tabs.map(choice => (
                                    <Tab key={choice.id} label={choice.name} value={choice.id} />
                                ))}
                            </Tabs>
                            <Divider />

                            <>

                                {isXSmall ?
                                    (<MobileGrid {...props} ids={this.state[filterValues.is_approved]} />) :
                                    (<div className="text-center">

                                        {filterValues.is_approved === "T" ?
                                            (
                                                this.state.T && this.state.T.length === 0 ?
                                                    <h5 className="text-center m-4">No Records</h5> :

                                                    <Datagrid
                                                        {...props}
                                                        ids={this.state.T}
                                                        optimized
                                                        rowClick="edit"
                                                        hasBulkActions={false}
                                                        bulkActionButtons="false"
                                                        source='T'
                                                        className="text-center"
                                                    >

                                                        <NumberField className={classes.centerText} source="employeeid" label="Employee ID" />
                                                        <TextField source="contactnumber" label="Phone" />
                                                        <TextField source="name" label="Name" />
                                                        <EmailField source="email" label="Email" />
                                                        <TextField source="designation" label="Designation" />
                                                        <TextField source="organizationname" label="Organization" />
                                                    </Datagrid>
                                            ) : <></>
                                        }
                                        {filterValues.is_approved === "P" ?
                                            <div className="text-center">

                                                {this.state.P && this.state.P.length === 0 ?
                                                    <h5 className="text-center m-4">No Records</h5> :
                                                    <div className="text-center">
                                                        <Datagrid {...props}
                                                            ids={this.state.P}
                                                            rowClick="edit"
                                                            className="text-center"
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <NumberField className={`${classes.centerText} text-center`} source="employeeid" label="Employee ID" />
                                                            <TextField className={classes.centerText} source="contactnumber" label="Phone" />
                                                            <TextField source="name" label="Name" />
                                                            <EmailField source="email" label="Email" />
                                                            <TextField source="designation" label="Designation" />
                                                            <TextField source="organizationname" label="Organization" />
                                                        </Datagrid>
                                                    </div>}
                                            </div> : <></>
                                        }
                                        {filterValues.is_approved === "R" ?
                                            (
                                                this.state.R && this.state.R.length === 0 ?
                                                    <h5 className="text-center m-4">No Records</h5> :
                                                    <Datagrid {...props}
                                                        ids={this.state.R}
                                                        hasBulkActions={false}
                                                        className="text-center"
                                                        rowClick="edit"
                                                    >
                                                        <NumberField source="employeeid" label="Employee ID" />
                                                        <TextField source="contactnumber" label="Phone" />
                                                        <TextField source="name" label="Name" />
                                                        <EmailField source="email" label="Email" />
                                                        <TextField source="designation" label="Designation" />
                                                        <TextField source="organizationname" label="Organization" />
                                                    </Datagrid>
                                            ) : <></>
                                        }
                                    </div>)}

                            </>

                        </Fragment> : <></>
                    )}
            </>
        );
    }
}

const StyledTabbedDatagrid = props => {
    const classes = useDatagridStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();

    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        setState(state => ({

            ...state,

            role: role

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])

    return (
        <>
            {state && state.role &&
                <TabbedDatagrid role={state.role} classes={classes} isXSmall={isXSmall} {...props} />
            }
        </>
    )
};

StyledTabbedDatagrid.defaultProps = {
    textAlign: 'center',
}

export default StyledTabbedDatagrid;
