import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { history } from "../helpers/history";
import Landing from "../layout/Landing";
import Login from "../components/userManagement/Login";
import Register from "../components/userManagement/Register";
import Settings from "../layout/Settings";
import OrganizationForm from "../components/userManagement/OrganizationForm";
import DeleteAccount from "../components/userManagement/DeleteAccount";
import ViewCard from "../components/userManagement/ViewCard";
import AddTemplates from "../components/users/superAdmin/templates/AddTemplates";

export default function SharedRoute() {
  history.listen((location, action) => {});

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/login" render={(props) => <Login {...props} />}></Route>
          <Route
            exact
            path="/sign-up"
            render={(props) => <Register {...props} />}
          ></Route>
          <Route
            path="/settings"
            render={(props) => <Settings {...props} />}
          ></Route>
          <Route
            path="/create-organization"
            render={(props) => <OrganizationForm {...props} />}
          ></Route>
          <Route
            path="/delete"
            render={(props) => <DeleteAccount {...props} />}
          ></Route>
          <Route
            path="/view-card/:id"
            render={(props) => <ViewCard {...props} />}
          />{" "}
          {/* Dynamic route for ViewCard */}
          <Route path="/add-templates" render={(props) => <AddTemplates {...props} />}></Route>
        </Switch>
      </Router>
    </>
  );
}
