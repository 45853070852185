import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  useNotify,
  useRecordContext,
  useRefresh,
  useVersion,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import axios from "axios";
import { NODE_API } from "../../../../config";
import { Divider, ListSubheader, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    margin: "0%",
    padding: "0%",
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inline: {
    display: "inline",
    margin: 5,
    padding: 10,
    color: "#0000008a",
  },
}));

export const ReplaceButton = (props) => {
  const record = useRecordContext();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({});
  const refresh = useRefresh();
  const history = useHistory();
  const version = useVersion();
  const notify = useNotify();

  const getOrgUserList = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;
    var details = [];

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(`${NODE_API}/organization/replace/users/list`, requestOptions)
      .then((res) => {
        details = res.data;
        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });

    setState((state) => ({
      ...state,
      userDetails: details,
      token: token,
    }));
  }, []);

  useEffect(() => {
    getOrgUserList();
  }, [version]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleListItemClick = async (value) => {
    if (value && value.email && value.email != "" && value.email != null) {
      await updateReplaceWithEmployee(value);
    }
    setOpen(false);
  };

  async function updateReplaceWithEmployee(data) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: state.token,
      },

      data: JSON.stringify({
        user_id: record.user_id,
        replace_with: data.id,
        id: record.id,
      }),
    };

    return axios(`${NODE_API}/organization/replace/users/list`, requestOptions)
      .then(async (res) => {
        if (res && res.status === 208) {
          notify(`Something went wrong try again later`, { type: "warning" });
          refresh();
        } else if (res && res.status === 200) {
          notify(`Replaced Successfully`, { type: "success" });
          refresh();
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
          refresh();
        }

        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
        history.push("/organization/users/list");
      });
  }

  return (
    <>
      {
        record.replace_with === null ? (
          <Button
            onClick={handleClick}
            title="Replace With"
            label="Replace"
            {...props}
            style={{ backgroundColor: "#e7e7e7", color: "black" }}
          />
        ) : (
          <Button
            onClick={handleClick}
            title="Replace Again"
            label="Replace Again"
            {...props}
            style={{ backgroundColor: "#ffffcc", color: "black", border: "1px solid #ffeb3b" }}
          />
        )
        // <span style={{ color: 'black' }}>Already Replaced</span>
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <List
            className={classes.root}
            subheader={
              <ListSubheader
                component="div"
                style={{ color: "black", fontWeight: "bold" }}
                id="nested-list-subheader"
              >
                Select Employee to Replace With
              </ListSubheader>
            }
          >
            {state.userDetails &&
              state.userDetails.map((ele) => (
                <>
                  <ListItem
                    button
                    key={ele.id}
                    alignItems="flex"
                    onClick={() => handleListItemClick(ele)}
                  >
                    <ListItemText
                      primary={`${ele.name}`}
                      secondary={
                        <Typography
                          component="span"
                          variant="inherit"
                          color="textSecondary"
                        >
                          <div>{`${ele.designation}`}</div>
                          <div>{`${ele.email}`}</div>
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
          </List>
        </Fade>
      </Modal>
    </>
  );
};
