import React from 'react';
import { PasswordInput } from 'react-admin';
import { SimpleForm, required, useNotify } from "react-admin";
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Redirect } from 'react-router';
import { NODE_API } from '../config';
var CryptoJS = require("crypto-js");


const validatePassword = [required()]

const Configuration = (props) => {

  const notify = useNotify();

  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  async function changePasswordFun(password) {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let token;

    if (user && user.validator) {
      token = user.validator
    } else {
      token = {}
    }

    var passwordEncryption = CryptoJS.SHA3(password);

    var encryptedLoginPassword = passwordEncryption.toString(CryptoJS.enc.Base64)

    const requestOptions = {
      method: 'PUT',
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: JSON.stringify({
        password: encryptedLoginPassword
      })

    };

    return axios(`${NODE_API}/auth/update`, requestOptions)

      .then(async res => {
        if (res && res.status === 200) {

          notify(`Password changed succesfully`, { type: 'success' })

        }

        return res;
      }).catch(e => {
        notify(`Something went wrong try again later`, { type: 'warning' })
        Redirect('/');

      });
  }

  const onsubmit = (data) => {

    data.password === data.confirmPassword ? changePasswordFun(data.password) : (
      notify(`The two passwords you entered do not match`, { type: 'error' })
    )

  };


  return isXSmall ? (


    <SimpleForm save={onsubmit}>
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>

      <PasswordInput
        source="password"
        label="password"
        style={{ width: '70%' }}
        validate={validatePassword}

      />
      <PasswordInput
        autoFocus
        source="confirmPassword"
        label="confirm password"
        style={{ width: '70%' }}
        validate={validatePassword}
      />

    </SimpleForm>


  ) : isSmall ? (


    <SimpleForm save={onsubmit}>
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>

      <PasswordInput
        source="password"
        label="password"
        style={{ width: '80%' }}
        validate={validatePassword}
      />
      <PasswordInput
        autoFocus
        source="confirmPassword"
        label="confirm password"
        style={{ width: '80%' }}
        validate={validatePassword}
      />

    </SimpleForm>


  ) :
    (

      <SimpleForm save={onsubmit}>
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>
        <PasswordInput
          autoFocus
          source="password"
          label="password"
          style={{ width: '40%' }}
          validate={validatePassword} />
        <PasswordInput
          source="confirmPassword"
          label="confirm password"
          style={{ width: '40%' }}
          validate={validatePassword}
        />

      </SimpleForm>

    );
};

export default Configuration;
