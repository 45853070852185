import React from 'react';
import Card from '@material-ui/core/Card';
import { PieChart } from 'react-minimal-pie-chart';
import CardContent from '@material-ui/core/CardContent';

const styles = {
    flex: { marginTop: '2em' },
    chart: { marginTop: '2em', marginBottom: '2em', padding: "0 20% 0 20%" }
}

const Chart = ({ value }) => {

    return (
        <Card style={styles.flex}>
            <CardContent style={styles.chart}>
                <PieChart
                    animate
                    animationDuration={500}
                    animationEasing="ease-out"
                    center={[50, 50]}
                    data={[
                        { color: '#31708f', title: 'Organizations', value: value.numberOrganization },
                        { color: '#4caf50', title: 'Business', value: value.numberBusinessCards },
                        { color: '#e38627', title: 'Personal', value: value.numberPersonalCards },

                    ]}

                    label={({ x, y, dx, dy, dataEntry }) => (
                        <text
                            x={x}
                            y={y}
                            dx={dx}
                            dy={dy}
                            dominant-baseline="central"
                            text-anchor="middle"
                            style={{
                                fill: 'rgba(0, 0, 0, 0.54)', pointerEvents: 'none', fontSize: '3px'
                            }}>
                            <tspan x={x} y={y} dx={dx} dy={dy}>{dataEntry.title}</tspan>
                            {dataEntry.title === "Organizations" ? <></> : <tspan x={x} y={y + 4} dx={dx} dy={dy}>Cards</tspan>}

                        </text>
                    )}
                />
            </CardContent>

        </Card>
    );
};

export default Chart;
