import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Landing.css'
import { BiLocationPlus } from "react-icons/bi";
import { BiGitCompare } from "react-icons/bi";
import { BiEnvelope } from "react-icons/bi";
import axios from 'axios';
import { NODE_API } from '../config';

export default class Landing extends Component {
  constructor(props) {
    super()
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

  }
  handleChange(event) {
    this.setState({
      [event.target.name]: [event.target.value]
    });
  }

  handleSubmit(event) {
    
    event.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers:
      {
        'Content-Type': 'application/json',

      },
      data: JSON.stringify({
        "name": this.state.name,
        "email": this.state.email,
        "subject": this.state.subject,
        "message": this.state.message
      })

    };

    return axios(`${NODE_API}/email/contact`, requestOptions)

      .then(async res => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: ""
        });

      }).catch(e => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: ""
        });
      });

  }
  render() {
    return (
      <>

        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet" />

        {/* <!-- Vendor CSS Files --> */}
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link href="assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        <link href="assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet" />
        <link href="assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet" />
        <link href="assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet" />
        <link href="assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />
        {/* <!-- ======= Header ======= --> */}
        <header id="header" class="fixed-top d-flex align-items-center">
          <div class="container d-flex align-items-center">
            <div class="logo1 me-auto">
              <h1><a onClick={() => window.location.replace("/#hero")}>SoViCa</a></h1>
            </div>
            <nav id="navbar" class="navbar ml-auto my-sm-0 topnav-right">
              <ul>
                <li><a href="#about">About Us</a></li>
                <li><a href="#team">Team</a></li>
                <li><a href="#contact">Contact</a></li>
                <li><a href="#testimonials">Testimonials</a></li>
                <li><a href="#founder">Founder</a></li>
                <li><Link to="/login">Login</Link></li>
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
        {/* <!-- ======= Hero Section ======= --> */}
        <section id="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 pt-1 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
                <div>
                  <h1 style={{ color: '#28a745',textShadow:'0 1px 0 #ccc, 0 2px 0 #ccc,0 3px 0 #ccc, 0 4px 0 #ccc,0 5px 0 #ccc, 0 6px 0 #ccc,0 7px 0 #ccc, 0 8px 0 #ccc,0 9px 0 #ccc, 0 10px 0 #ccc,0 11px 0 #ccc, 0 12px 0 #ccc,0 20px 30px rgba(0, 0, 0, 0.5)' }}>Towards a Greener Earth </h1>

                  <h5>SoViCa is a Platform and is standard for Soft Visiting Cards.<br />Also signifies So We Care - for mother earth.</h5>
                  <Link to='/login' className="btn-get-started scrollto">Get Started</Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left">
                <img src="/images/img/hero-img.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Hero --> */}
        <main id="main">
          {/* <!-- ======= About Section ======= --> */}
          <section id="about" className="about">
            <div className="container">
              <div className="row">
                <div className="col-lg-5" data-aos="zoom-in">
                  <img src="/images/img/about.jpg" className="img-fluid" alt="" />
                </div>
                <div className="col-lg-7 d-flex flex-column justify-contents-center " data-aos="fade-left">
                  <div className="content  pt-lg-0 ml-5">
                    <h3>Learn more about us</h3>
                    <p style={{ color: "black" }} className="text-sm-left text-sm">
                      SoViCa is a Platform and is standard for “Soft Visiting Cards”, we wanted to create a seamless experience that is better than the conventional means. That meant removing all existing barriers of card creation, issuance, sharing, storing and usage, and making the process as simple as possible.  SoViCa incidentally also signifies “So We Care- Care for the Nature and the Mother earth.” It is a small step to get everyone aligned to this and put in an effort, not only to save trees but also to ensure that we plant more and maintain them. At the same time, we intend to reduce CO2 emissions and reduce water pollution.
                      The matter of Saving Trees and Planting more of them has become a necessity rather than an option. Some stark facts are as under                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- End About Section --> */}
          {/* <!-- ======= benfits Section ======= --> */}
          <section id="services" className="services section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Services</h2>
                <p style={{ color: "black" }}>We are SoViCa and we are here to make digital cards smarter than ever, helping your businesses to</p>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                  <div className="icon-box icon-box-pink">
                    <div className="icon"><i className="bx bxl-dribbble"></i></div>
                    <p className="description" style={{ color: "black" }}>Digital Business Card Option as the core offering - includes Creating and managing Business Cards, Achieving Zero Wastage, Converting received Physical cards to digital cards, etc., adding Rich features to the Business Card functionality.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                  <div className="icon-box icon-box-cyan">
                    <div className="icon"><i className="bx bx-file"></i></div>
                    <p className="description" style={{ color: "black" }}> Business offerings and Discounts Option - Individuals and Businesses can upload offers and discounts. A new Method of being there, where needed at the right time needed.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon-box icon-box-green">
                    <div className="icon"><i className="bx bx-tachometer"></i></div>
                    <p className="description" style={{ color: "black" }}>Highway Emergency Services Option - Crowd sourcing option - Add on feature which helps app users to seek Emergency Highway services like Puncture-walas, Mechanics, Clinics etc when on the move.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-blue">
                    <div className="icon"><i className="bx bx-world"></i></div>
                    <p className="description" style={{ color: "black" }}>Digital Invoicing Option - Helps Businesses to directly raise and deliver digital invoices to the users. Helps user to manage multiple invoices in a jiffy.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======= Services Section ======= --> */}
          <section id="services" className="services section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Benefits and Features</h2>
              </div>
              <div className="col-lg-12" data-aos="zoom-in">
                <img src="/images/img/benefits.png" className="img-fluid" alt="" />
              </div>
            </div>
          </section>
          {/* ====== Founder ======  */}
          <section id="founder" className="founder">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Founder & CEO</h2>
                <p style={{ color: "black" }}>About the Founder </p>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="100">
                    <div className="pic"><img src="/images/img/founder.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h5 className='mt-1'>Mr. Prashant N. Rajput</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 d-flex flex-column justify-contents-center " data-aos="fade-left">
                  <div className="content  pt-lg-0 ml-5">
                    <p style={{ color: "black" }} className="text-sm-left text-md">
                      "Who says you need to start early to succeed......well at least not me".....is something that he believes in.....starting a Startup at the Age of 52.. "As a Toddler he played around with a piece of paper. Very early in his growing up years....he unconsciously started using as little of paper as he could. During his college days he would carry one single notebook....home made by himself....using papers remaining in used note books. Till date he is a known miser when it comes to using Paper. ............". as told by his parents.
                    </p>
                    <p style={{ color: "black" }} className="text-sm-left text-md">
                      Prashant has always been appreciated for his technical soundness of products, creative ideas and his ability to perfectly comprehend user behavior. He is a Seasoned Sales Professional with 29 years of associating with people. He has been a Sales Guy, a Trainer, a Thinker, a Strategist, an Innovist and more importantly a great Friend at work. An MBA from Kousalli Institute of Management Studies, his beginnings were very humble. His passion has been Softwares since his early years. Though he has no formal qualifications in IT domain, his understanding of Product and Process Flows is meticulous. Communication is one of the biggest assets of Prashant. His Vision, is to leave an Earth that is as Green as he had seen it when he was a child. A strong believer in social responsibilities......one of the key focus now is make this world a better place.                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- End Services Section --> */}
          {/* <!-- ======= Team Section ======= --> */}
          <section id="team" className="team">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Our Team</h2>
                <p style={{ color: "black" }}>Meet the Team Behind Sovica. </p>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="100">
                    <div className="pic"><img src="/images/img/ravi.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Ravi Deshmukh</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="200">
                    <div className="pic"><img src="/images/img/rahul.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Rahul Rajput</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="200">
                    <div className="pic"><img src="/images/img/pavan.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Pavan Padole</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="200">
                    <div className="pic"><img src="/images/img/anand.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Anand Bholane</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in" data-aos-delay="200">
                    <div className="pic"><img src="/images/img/kaustubh.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Kaustubh Barhate</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="member" data-aos="zoom-in">
                    <div className="pic"><img src="/images/img/sandhya.jpg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Sandhya Damare</h4>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </section>
          {/* <!-- End Team Section --> */}
          {/* <!-- ======= Testimonials Section ======= --> */}
          <section id="testimonials" className="testimonials">
            <div className="container">
              <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item mb-4">
                      <p style={{ color: "black" }}>
                        <i className="bx bxs-quote-alt-left quote-icon-left "></i>
                        <sup className='text-md font-weight-bold'>SoViCa the Platform - brings out the immense potential, a humble Visiting card can bring about in its Digitised Avatar. The Platform is extremely well thought about and weaves up an array of simple but strong functionalities around the digitised card and will become a boon for everyone who downloads and or Subscribes to the services.</sup>
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      <img src="/images/img/sakhare.png" className="testimonial-img" alt="" />
                      <h3>Gajanan Sakhare</h3>
                      <h4>Director &amp; SmartCloud
                        Infoservices </h4>
                    </div>
                  </div>
                  {/* <!-- End testimonial item --> */}
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <p style={{ color: "black" }}>
                        <i className="bx bxs-quote-alt-left quote-icon-left text-md"></i>
                        <sup className='text-md font-weight-bold'> It's amazing idea, where our flora will be secured, Every share of this e-visiting card will be able to save trees. It will really reduce burden on forest. Will completly endose this product for future of our green.</sup>
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      <img src="/images/img/vijay.jpg" className="testimonial-img" alt="" />
                      <h3>Vijay Nishant</h3>
                      <h4>Tree Doctor</h4>
                    </div>
                  </div>
                  {/* <!-- End testimonial item --> */}
                </div>
                <div className="swiper-pagination"></div>
              </div>

            </div>
          </section>
          {/* <!-- End Testimonials Section --> */}
          {/* <!-- ======= Clients Section ======= --> */}
          {/* <!-- ======= Contact Section ======= --> */}
          <section id="contact" className="contact1 section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Contact Us</h2>
              </div>
              <div className="row">
                <div className="col-lg-5 d-flex align-items-stretch" data-aos="fade-right">
                  <div className="info">
                    <div className="address">
                      <i ><BiLocationPlus /></i>
                      <h4>Location:</h4>
                      <p>Pune, Maharashtra 411052 Prashant Rajput</p>
                    </div>
                    <div className="email">
                      <i ><BiGitCompare /></i>
                      <h4>Organisation:</h4>
                      <p>Ajar Technologies Pvt Ltd</p>
                    </div>
                    <div className="phone">
                      <i ><BiEnvelope /></i>
                      <h4>Email:</h4>
                      <p>techsupport@ajartech.in</p>
                    </div>
                    <iframe src="https://maps.google.com/maps?width=100%25&amp;height=290&amp;hl=en&amp;q=pune%20maharashtra+(Sovica)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameBorder="0" style={{ border: 0, width: "100%", height: "290px" }} allowFullScreen></iframe>
                  </div>
                </div>
                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-left">
                  <form role="form" className="php-email-form" onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Your Name</label>
                        <input type="text" name="name" className="form-control" id="name" value={this.state.name} onChange={this.handleChange} required />
                      </div>
                      <div className="form-group col-md-6 mt-3 mt-md-0">
                        <label htmlFor="name">Your Email</label>
                        <input type="email" className="form-control" name="email" id="email" value={this.state.email} onChange={this.handleChange} required />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="name">Subject</label>
                      <input type="text" className="form-control" name="subject" id="subject" value={this.state.subject} onChange={this.handleChange} required />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="name">Message</label>
                      <textarea className="form-control" name="message" rows="10" value={this.state.message} onChange={this.handleChange} required ></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-2 col-md-6 ">
                  <div class="footer-info">
                    <img src="/images/img/logo2.png" className="img-fluid" alt="" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 footer-links ml-4 " >
                  <h4 >Location</h4>
                  <ul >
                    Pune Maharashtra 411052 <br />
                    Prashant Rajput<br />
                    <strong>Email:</strong> support@ajartech.in<br />
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 footer-linksmt-3 ml-3" >
                  <h4 >Useful Links</h4>
                  <ul style={{ listStyleType: 'none' }}>
                    <li><a href="#about" class="bx bx-chevron-right">About Us</a></li>
                    <li><a href="#team" class="bx bx-chevron-right">Team</a></li>
                    <li><a href="#contact" class="bx bx-chevron-right">Contact</a></li>
                    <li><a href="#testimonials" class="bx bx-chevron-right">Testimonials</a></li>
                    <li><a href="#founder" class="bx bx-chevron-right">Founder</a></li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 footer-links mt-3">
                  <h4>Our Services</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Digital OutReach Platform</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Business Search Platform</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Business Cards Platform</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Hiring Visibility Platform</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Social
                      Update Platform*</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="copyright">
              <strong>Copyright © 2022 &nbsp;<a href="https://sovica.in">Sovica.in</a></strong>
              .&nbsp;All rights reserved.
            </div>
          </div>
        </footer>
        <a href="!#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        {/* <Footer /> */}
      </>
    )
  }
}
