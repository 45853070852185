import React, { useCallback, useEffect, useState } from 'react';
import {
    useEditController,
    useTranslate,
    SimpleForm,
    TextField,
    EmailField,
    NumberField,
    useDataProvider,
    useVersion,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import RequestEditToolbar from './RequestEditToolbar';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    inlineFormEntity: {
        display: "inline-block",
        color: 'black',
        width: '50%'
    },
    inlineFormEntity2: {
        display: "inline-block",
        // marginLeft: '%',
        color: 'black',

    }

}));

const RequestEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();

    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();

    const checkUserRole = useCallback(async () => {

        let user = JSON.parse(sessionStorage.getItem('user'));
        let role, path;


        if (user && user.type) {
            role = user.type
        } else {
            role = {}
        }

        role && role === 'LOCATION_ADMIN' ? path = 'organization/location/users/cards/request/:id' :
            (role && role === 'CORPORATE_ADMIN' ? path = 'organization/users/cards/request/:id' : path = '/login')


        setState(state => ({

            ...state,

            path: path,

            role: role

        }));

    }, [dataProvider])


    useEffect(() => {
        checkUserRole()
    }, [version])

    if (!controllerProps.record) {
        return null;
    }
    const handleCallback = (childData) => {
        alert('handleCallback :' + childData)
    }
    return (
        <>
            {state && state.role == 'LOCATION_ADMIN' ?

                <div className={classes.root}>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {translate('Request Details')}
                        </Typography>

                        <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <SimpleForm
                        className={classes.form}
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        version={controllerProps.version}
                        redirect="list"
                        resource="T"
                        toolbar={<RequestEditToolbar />}>

                        <NumberField fullWidth={true} formClassName={classes.inlineFormEntity} source="employeeid" />
                        <TextField fullWidth={true} formClassName={classes.inlineFormEntity2} source="contactnumber" />
                        <TextField fullWidth={true} formClassName={classes.inlineFormEntity} source="name" />
                        <EmailField fullWidth={true} formClassName={classes.inlineFormEntity} source="email" />
                        <TextField fullWidth={true} formClassName={classes.inlineFormEntity} source="designation" />
                        <TextField formClassName={classes.inlineFormEntity} source="locname" />
                        {/* <ShowCorporateCard sendCardFront={handleCallback} /> */}


                    </SimpleForm>
                </div>

                : (state.role == 'CORPORATE_ADMIN' ?

                    <div className={classes.root}>
                        <div className={classes.title}>
                            <Typography variant="h6">
                                {translate('Request Details')}
                            </Typography>
                            <IconButton onClick={onCancel}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <SimpleForm
                            className={classes.form}
                            basePath={controllerProps.basePath}
                            record={controllerProps.record}
                            save={controllerProps.save}
                            version={controllerProps.version}
                            redirect="list"
                            resource="T"
                            toolbar={<RequestEditToolbar />}
                        >

                            <NumberField fullWidth={true} formClassName={classes.inlineFormEntity} source="employeeid" />
                            <TextField fullWidth={true} formClassName={classes.inlineFormEntity2} source="contactnumber" />
                            <TextField fullWidth={true} formClassName={classes.inlineFormEntity} source="name" />
                            <EmailField fullWidth={true} formClassName={classes.inlineFormEntity} source="email" />
                            <TextField fullWidth={true} formClassName={classes.inlineFormEntity} source="designation" />
                            <TextField fullWidth={true} formClassName={classes.inlineFormEntity} source="organizationname" />

                        </SimpleForm>
                    </div>
                    :
                    <></>
                )
            }

        </>
    );
};

export default RequestEdit;
